import React, { useEffect, useState } from "react";
import logo from '../../assets/img/logo.svg';
import "./Menu.css";
import { observer } from "mobx-react-lite";
import store from "../../providers/store";
import Text from "../basics/Text";
import makeStyles from "@material-ui/core/styles/makeStyles";
import AdminLinks from "./AdminLinks";
import AccountsLinks from "./AccountsLinks";
import Box from "@material-ui/core/Box";
import ModalConfirmDisconnect from "../ModalConfirmDisconnect";
import MobileMenuButton from "./MobileMenuButton";
import Divider from "@material-ui/core/Divider";
import { useHistory } from 'react-router-dom';
import useSWR from "swr";
import { fetcher } from "../../providers/axios";
import Notifications from "./Notifications";
import SearchInput from "./SearchInput";
import startSocket from '../../providers/socketio';
import { FaSignOutAlt } from "react-icons/fa";


const useStyles = makeStyles(theme => ({
    link: {
        '&:hover, &.active': {
            color: theme.palette.primary.main + '!important'
        }
    },
    menuLink: {
        cursor: 'pointer',
        '& > .MuiBox-root:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.04)'
        }
    },
    menuXs: {
        '& svg': {
            color: "#CACCCF"
        },
        [theme.breakpoints.up('sm')]: {
            display: 'none'
        },
        color: 'white',
        boxShadow: '0px 4px 16px rgba(16, 30, 115, 0.08)',
        width: '100vw'

    },
    menuLg: {
        '& svg': {
            color: "#CACCCF"
        },
        [theme.breakpoints.down('xs')]: {
            display: 'none'
        }
    },
    divider: {
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2)
    },
    badge: {
        '& .MuiBadge-badge': {
            border: 'solid 2px white!important',
            color: 'white'
        }
    },
    notificationNotRead: {
        backgroundColor: 'rgba(29, 112, 183, 0.1)',
        '& .MuiDivider-root': {
            backgroundColor: 'rgba(29, 112, 183, 0.4)!important'
        },
        '& svg': {
            color: 'rgb(29, 112, 183)'
        }
    },
    notificationRead: {
        '& svg': {
            color: "#1D70B7",
            opacity: ".48"
        }
    },
    notificationsBox: {
        /*
        '& > button': {
            height: 50,
            width: 50,
            '&:focus': {
                backgroundColor: 'white'
            }
        }
        */
    },
    hideSm: {
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        }
    },
    logo: {
        width: "115px",
        borderRadius: 8
    }
}));

const Menu = (props) => {
    const classes = useStyles();
    const [modalConfirmDisconnectOpen, setModalConfirmDisconnectOpen] = useState(false);
    const [linkActive, setLinkActive] = useState('');
    const history = useHistory();

    function toggleModalConfirmDisconnect() {
        setModalConfirmDisconnectOpen(!modalConfirmDisconnectOpen);
    }

    const { data: notifications, mutate: mutateNotification } = useSWR('notifications', fetcher, {
        revalidateOnFocus: false,
    });



    const links = [
        {
            link: '/messages/received',
            name: 'Boite de récéption',
            active: store.currentRoute === '/messages/received'
        },
        {
            link: '/messages/send',
            name: 'Messages envoyés',
            active: store.currentRoute === '/messages/send'
        },
        {
            link: '/users',
            name: 'Annuaire',
            active: store.currentRoute === '/users'
        },
        {
            link: '/files',
            name: 'Fichiers',
            active: store.currentRoute.includes('files')
        }
    ];


    function handleDisconnect(event) {
        store.disconnect();
    }

    useEffect(() => {
        startSocket();
    }, []);

    useEffect(() => {
        if (store.currentRoute !== history.location.pathname) {
            if (!store.currentRoute.length) {
                store.goToLink('/messages/received');
            } else {
                history.push(store.currentRoute);
            }
        } else {
            
        }
    }, [store.currentRoute])


    return (
        <React.Fragment>
            <header className={classes.menuXs}>
                <Box component="span" display="flex" justifyContent="space-between" alignItems="center" p={2}>
                    <MobileMenuButton links={links} />
                    <Box display="flex" justifyContent="flex-end" alignItems="center">
                        <Box style={{ cursor: 'pointer' }} mr={2} ml={0}>
                            <Notifications mutate={mutateNotification} classes={classes} notifications={notifications} />
                        </Box>
                        <Box style={{ cursor: 'pointer' }} ml={0} onClick={toggleModalConfirmDisconnect}>
                            <FaSignOutAlt fontSize={22} />
                        </Box>
                    </Box>
                </Box>
            </header>
            <header className={classes.menuLg}>

                <div className="menu-header">
                    <div>
                        <img alt="logo" className={classes.logo} title="logo" src={logo} />
                        <Box ml={6}>
                            <SearchInput />
                        </Box>
                    </div>
                    <div>
                        <Box className={classes.hideSm} style={{ cursor: 'pointer' }} mr={0} ml={0}>
                            <Text type="subtitle2">
                                Bonjour {store.auth.firstname} !
                            </Text>
                        </Box>

                        <Box mr={2} ml={2} className={classes.notificationsBox}>
                            {/* 
                            <Badge badgeContent={(notifications || []).length} color="error" className={classes.badge}>
                                <BellIcon/>
                            </Badge>
                            */}
                            <Notifications mutate={mutateNotification} classes={classes} notifications={notifications} />
                        </Box>
                        <Box style={{ cursor: 'pointer' }} mr={0} ml={0} onClick={toggleModalConfirmDisconnect}>
                            <FaSignOutAlt fontSize={22} />
                        </Box>

                    </div>
                </div>

                <Box mt={2} mb={5}>
                    <nav className="menu">
                        {links.map(link => (
                            <Box mr={2} ml={2} key={link.link} style={{cursor: 'pointer'}}>
                                <Box key={link.link} onClick={e => store.goToLink(link.link)}>
                                    <Text type="subtitle2" className={`${classes.link} ${link.active ? 'active' : ''}`} component="span">
                                        {link.name}
                                    </Text>
                                </Box>
                            </Box>
                        ))}


                        <Divider className={classes.divider} flexItem orientation="vertical" />


                        {store.isAuth && store.auth.role === 'admin' && (
                            <AdminLinks classes={classes} />
                        )}
                        <AccountsLinks classes={classes} />


                    </nav>
                </Box>

            </header>
            <ModalConfirmDisconnect onConfirm={handleDisconnect} open={modalConfirmDisconnectOpen}
                toggle={toggleModalConfirmDisconnect} />
        </React.Fragment>
    );
};


export default observer(Menu);