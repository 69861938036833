import React, {Component, useRef, useState} from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Dialog from "@material-ui/core/Dialog";
import Text from "./basics/Text";
import Box from "@material-ui/core/Box";
import {Button, TextField} from "@material-ui/core";
import CustomAutocomplete from "./basics/CustomAutocomplete";
import {Grid} from "@material-ui/core";
import {EditorState} from 'draft-js';
import FormControl from "@material-ui/core/FormControl";
import Input from "./basics/Input";
import {Editor} from 'react-draft-wysiwyg';
import useSWR from "swr";
import axios, {fetcher} from "../providers/axios";
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import GroupsAndUsersSearchInput from "./GroupsAndUsersSearchInput";
import SendIcon from "./icons/SendIcon";
import {useSnackbar} from "notistack";
import FileAttachmentInput from "./basics/FileAttachmentInput";

const useStyle = makeStyles(theme => ({
    row: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'baseline',

    },
    rowButtons: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center'
    },
    dialog: {
        padding: '15px',
        [theme.breakpoints.down('sm')]: {
            '&: .MuiDialog-paper': {
                margin: 0,
            }
            
            
        }
    },
    content: {
        padding: theme.spacing(2),
        minWidth: '25vw',
        
    },
    closeBox: {
        color: '#9FA2B4',
        fontWeight: 400,
        fontSize: '24px',
        letterSpacing: '0.2px'
    },
    editorClassName: {
        paddingLeft: "20px",
        paddingRight: "20px",
        border: '1px solid #ced4da',
        borderRadius: '4px',
        minHeight: '200px',
        cursor: 'text'
    },
    removeBorder: {
        border: 'none'
    },
    removePaddingLeft: {
        paddingLeft: '0px'
    },
    changeBorderStyle: {
        marginLeft: '0px',
        marginRight: '5px',
        border: 'none',
        '&:hover': {
            border: '1px solid #ced4da'
        }
    }
}));

interface INewMessageProps {
    onSend?: Function,
    children: any,
    users?: [any],
    key?: any,
    className?: string
}

export default (props: INewMessageProps) => {
    const {onSend, children, users} = props;
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const classes = useStyle();
    const [open, setOpen] = useState(false);
    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const [usersSelected, setUserSelected] = useState([]);
    const editorHtmlRef = useRef(null);
    const [groupsAndUsers, setGroupsAndUsers] = useState(users || []);


    function toggleOpen() {
        setOpen(!open);
    }

    function emptyData(event) {
        setGroupsAndUsers([]);
        for (let elem  of event.target.elements) {
            elem.value = "";
        }
        setEditorState(EditorState.createEmpty());
    }


    const send = async (event) => {
        event.preventDefault();

        if (editorHtmlRef.current) {
            const messageHTML = editorHtmlRef.current.editor.editor.innerHTML;
            try {
                const formData = new FormData(event.target);

                
                

                formData.append('usersIds', JSON.stringify(groupsAndUsers.filter(groupOrUser => !!groupOrUser.email).map(({id}) => id)));
                formData.append('groupsIds', JSON.stringify(groupsAndUsers.filter(groupOrUser => !groupOrUser.email).map(({id}) => id)));
                formData.append('message', messageHTML);
                
                
                const {data} = await axios.post('conversation/message', formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    }
                });

                /*
                const dataSend = {
                    file: attachments,
                    message: messageHTML,
                    object: formData.get('object'),
                    usersIds: groupsAndUsers.filter(groupOrUser => !!groupOrUser.email).map(({id}) => id),
                    groupsIds: groupsAndUsers.filter(groupOrUser => !groupOrUser.email).map(({id}) => id),
                };
                const {data} = await axios.post('conversation/message', dataSend);
                */
                if (typeof onSend === 'function') {
                    onSend(data);
                }
                
 
                emptyData(event);
                enqueueSnackbar('Message envoyé avec succès !', {variant: 'success'});

            } catch (err) {
                console.error(err);
                enqueueSnackbar('Erreur lors de l\'envoi du message', {variant: 'error'});
            }
            toggleOpen();
        }
    };

    return (
        <>
            <span onClick={toggleOpen} >{children}</span>
            <Dialog onClose={toggleOpen} className={classes.dialog} open={open}>

                <div className={classes.content}>
                    <Box mb={1} className={classes.rowButtons}>
                        <Box component="span" style={{cursor: 'pointer'}} onClick={toggleOpen}>
                            <span className={classes.closeBox}>X</span>
                        </Box>
                    </Box>
                    <Box mr={2} ml={2}>
                        <Text type="h3">
                            Nouveau Message
                        </Text>
                    </Box>
                    <form onSubmit={send} ref={null}>
                        <Box mb={1} mt={1}>
                            <Grid container>
                                <Grid item xs={12}>
                                    <Box mr={2} ml={2}>
                                        <FormControl fullWidth>
                                            <Box mt={2} mb={.3}>
                                                <label>
                                                    <Text type="body1">
                                                        Destinataires
                                                    </Text>
                                                </label>
                                            </Box>
                                            <GroupsAndUsersSearchInput
                                                value={groupsAndUsers}
                                                onChange={(e, newValue) => {
                                                    setGroupsAndUsers(newValue)
                                                }}
                                            />
                                        </FormControl>
                                    </Box>
                                </Grid>
                                <Grid item xs={12}>
                                    <Box mr={2} ml={2}>
                                        <FormControl fullWidth>
                                            <Box mt={1} mb={.3}>
                                                <label>
                                                    <Text type="body1">
                                                        Objet
                                                    </Text>
                                                </label>
                                            </Box>
                                            <Input required fullWidth id="object" name="object"/>
                                        </FormControl>
                                    </Box>
                                </Grid>
                                <Grid item xs={12}>
                                    <Box mr={2} ml={2}>
                                        <FormControl fullWidth>
                                            <Box mt={1} mb={.3}>
                                                <label>
                                                    <Text type="body1">
                                                        Ajouter un/des fichier(s)
                                                    </Text>
                                                </label>
                                            </Box>
                                            {/*
                                            <Input name="files" fullWidth  type="file" inputProps={{multiple: true}} />
                                            */}
                                            <FileAttachmentInput  name="files" />
                                        </FormControl>
                                    </Box>
                                </Grid>
                                <Grid item xs={12}>
                                    <Box mr={2} ml={2}>
                                        <FormControl fullWidth>
                                            <Box mt={1} mb={.3}>
                                                <label>
                                                    <Text type="body1">
                                                        Message
                                                    </Text>
                                                </label>
                                            </Box>
                                            <Editor
                                                ref={editorHtmlRef}
                                                editorState={editorState}
                                                onEditorStateChange={setEditorState}
                                                editorClassName={classes.editorClassName}
                                                toolbarClassName={classes.removeBorder}
                                                toolbar={{
                                                    className: classes.removePaddingLeft,
                                                    options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker',  /*'link', 'image',*/  'remove', 'history'],

                                                    inline: {
                                                        inDropdown: false,
                                                        component: undefined,
                                                        dropdownClassName: undefined,
                                                        options: ['bold', 'italic', 'underline', 'strikethrough'],
                                                        bold: {className: [classes.changeBorderStyle]},
                                                        italic: {className: classes.changeBorderStyle},
                                                        underline: {className: classes.changeBorderStyle},
                                                        strikethrough: {className: classes.changeBorderStyle},
                                                    },
                                                    blockType: {className: classes.removeBorder},
                                                    fontSize: {className: classes.removeBorder},
                                                    fontFamily: {className: classes.removeBorder},
                                                    list: {
                                                        inDropdown: false,
                                                        className: classes.removeBorder,
                                                        component: undefined,
                                                        dropdownClassName: undefined,
                                                        options: ['unordered', 'ordered', 'indent', 'outdent'],
                                                        unordered: {className: classes.changeBorderStyle},
                                                        ordered: {className: classes.changeBorderStyle},
                                                        indent: {className: classes.changeBorderStyle},
                                                        outdent: {className: classes.changeBorderStyle},
                                                    },
                                                    textAlign: {
                                                        inDropdown: false,
                                                        className: classes.removeBorder,
                                                        component: undefined,
                                                        dropdownClassName: undefined,
                                                        options: ['left', 'center', 'right', 'justify'],
                                                        left: {className: classes.changeBorderStyle},
                                                        center: {className: classes.changeBorderStyle},
                                                        right: {className: classes.changeBorderStyle},
                                                        justify: {className: classes.changeBorderStyle},
                                                    },
                                                    colorPicker: {className: classes.removeBorder},
                                                    link: {
                                                        inDropdown: false,
                                                        className: classes.removeBorder,
                                                        component: undefined,
                                                        popupClassName: undefined,
                                                        dropdownClassName: undefined,
                                                        showOpenOptionOnHover: true,
                                                        defaultTargetOption: '_self',
                                                        options: ['link', 'unlink'],
                                                        link: {className: classes.changeBorderStyle},
                                                        unlink: {className: classes.changeBorderStyle},
                                                        linkCallback: undefined
                                                    },
                                                    image: {className: classes.removeBorder},
                                                    remove: {className: classes.removeBorder},
                                                    history: {className: classes.removeBorder}

                                                }}
                                            />

                                        </FormControl>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                        <Box mb={1} mt={2} mr={2} ml={2} className={classes.row}>
                            <Box mr={1}>
                                <Button variant="contained" color="primary" type="submit">
                                    <Box component="span" mr={.5}>
                                        <SendIcon />
                                    </Box>
                                    <Text type="small1" color="white">
                                        ENVOYER
                                    </Text>
                                </Button>
                            </Box>
                            <Box>
                                <Button onClick={toggleOpen} type="button">
                                    <Text type="small1">
                                        ANNULER
                                    </Text>
                                </Button>
                            </Box>

                        </Box>
                    </form>
                </div>
            </Dialog>
        </>
    )
}
