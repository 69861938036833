import { action, computed, makeObservable, observable } from "mobx";

export class Store {
    constructor() {
        makeObservable(this);
    }

    @observable ui = {
        drawerOpen: true,
        title: ''
    };

    @observable auth = null;
    @observable jwt: string = '';
    @observable currentRoute: string = '';

    @action goToLink(link: string) {
        this.currentRoute = link;
    }

    @action setAuth(auth, jwt: string = null) {
        this.auth = auth;
        localStorage.setItem('auth', JSON.stringify(auth));
        if (jwt !== null) {
            this.jwt = jwt;
            localStorage.setItem('jwt', jwt);
        }
    }

    @action disconnect() {
        this.auth = null;
        this.jwt = '';

        localStorage.removeItem('jwt');
        localStorage.removeItem('auth');
    }

    @action setUI(ui) {
        this.ui = {
            ...this.ui,
            ...ui
        };
    }

    @computed get isAuth() {
        if (!this.jwt && localStorage.getItem('jwt') && localStorage.getItem('auth')) {
            try {
                this.auth = JSON.parse(localStorage.getItem('auth'));
                this.jwt = localStorage.getItem('jwt');
            } catch (err) {}
        }
        return this.jwt && this.jwt.length;
    }

}

const store = new Store();
export default store;