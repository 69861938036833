import React, {useEffect, useRef, useState} from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Dialog from "@material-ui/core/Dialog";
import Box from "@material-ui/core/Box";
import ErrorIcon from "./icons/ErrorIcon";
import Text from "./basics/Text";
import Button from "@material-ui/core/Button";
import {Grid} from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import Input from "./basics/Input";
import Select from "./basics/Select";
import axios from '../providers/axios';
import {CompactPicker, TwitterPicker  } from 'react-color';
import GroupsAndUsersSearchInput from "./GroupsAndUsersSearchInput";
import { useSnackbar } from 'notistack';

interface IEditGroupProps {
    children: any,
    group?: any,
    mutate: Function
}

const useStyle = makeStyles(theme => ({
    row: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'baseline'
    },
    rowButtons: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center'
    },
    dialog: {
        padding: '15px'
    },
    content: {
        padding: theme.spacing(2),
        minWidth: '25vw'
    },
    closeBox: {
        color: '#9FA2B4',
        fontWeight: 400,
        fontSize: '24px',
        letterSpacing: '0.2px'
    }
}));

export default (props: IEditGroupProps) => {
    const classes = useStyle();
    const [open, setOpen] = useState(false);
    const [color, setColor] = useState('#fff');
    const formRef = useRef(null);
    const [users, setUsers] = useState([]);
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    function toggleOpen() {
        setOpen(!open);
    }

    async function edit(event) {
        event.preventDefault();
        const isAddAction = !(props.group && props.group.id);

        const formData = new FormData(event.target);

        let data = {color};
        for (const property of ['name']) {
            data[property] = formData.get(property);
        }
        if (users && users.length) {
            data['users'] = users;
        }
        try {
            if (isAddAction) {
                const group = (await axios.post('groups', data)).data;
                /*
                props.mutate(groups => {
                    if (Array.isArray(groups)) {
                        return [...groups, group];
                    }
                    return [group];
                }, false);
                 */
            } else {
                const group = (await axios.put(`groups/${props.group.id}`, data)).data;
                /*
                props.mutate(groups => {
                    if (Array.isArray(groups)) {
                        groups.map(grp => {
                            if (grp.id == group.id) {
                                return group;
                            }
                            return grp;
                        });
                    }
                    return [group];
                }, false);
                 */
            }
            enqueueSnackbar('Groupe enregistré avec succès !', {variant: 'success'});
            props.mutate();
            toggleOpen();

        } catch (err) {
            enqueueSnackbar('Erreur lors de l\'enregistrement du groupe', {variant: 'error'});
            toggleOpen();
        }
    }

    useEffect(() => {
        let interval = setInterval(() => {
            if ((formRef && formRef.current) || !props.group) {
                if (props.group) {
                    if (props.group.color) {
                        setColor(props.group.color);
                    }
                    if (props.group.users) {
                        setUsers(props.group.users);
                    }
                    for (const property in props.group) {
                        if (formRef.current[property]) {
                            formRef.current[property].value = props.group[property];
                        }
                    }
                }
                clearInterval(interval);
            }
        }, 50);

        return () => {
            clearInterval(interval)
        };
    }, [open]);

    return (
        <>
            <span onClick={toggleOpen}>
                {props.children}
            </span>
            <Dialog onClose={toggleOpen} className={classes.dialog} open={open}>
                <div className={classes.content}>
                    <Box mb={.1} className={classes.rowButtons}>
                        <Box component="span" style={{cursor: 'pointer'}} onClick={toggleOpen}>
                            <span className={classes.closeBox}>X</span>
                        </Box>
                    </Box>
                    {props.group && props.group.id ? (
                        <Text type="h3">
                            Modifier "{props.group.name}"
                        </Text>
                    ) : (
                        <Text type="h3">
                            Nouveau groupe
                        </Text>
                    )}
                    <form onSubmit={edit} ref={formRef}>
                        <Box mb={1} mt={1}>
                            <Grid container>
                                <Grid item xs={12} sm={12}>
                                    <Box mr={2}>
                                        <FormControl fullWidth>
                                            <Box mt={2} mb={.3}>
                                                <label htmlFor="name">
                                                    <Text type="body2">
                                                        Nom
                                                    </Text>
                                                </label>
                                            </Box>
                                            <Input required fullWidth id="name" name="name"/>
                                        </FormControl>
                                        <FormControl fullWidth>
                                            <Box mt={2} mb={.3}>
                                                <label>
                                                    <Text type="body2">
                                                        Membres
                                                    </Text>
                                                </label>
                                            </Box>
                                            <GroupsAndUsersSearchInput
                                                onlyUsers
                                                value={users}
                                                onChange={(e, newValue) => {
                                                   setUsers(newValue)
                                                }}
                                            />
                                        </FormControl>
                                        <FormControl>
                                            <Box mt={2} mb={.3}>
                                                <label>
                                                    <Text type="body2">
                                                        Couleur
                                                    </Text>
                                                </label>
                                            </Box>

                                            <TwitterPicker triangle="hide" color={color} onChangeComplete={c => setColor(c.hex)} />
                                        </FormControl>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                        <Box mb={1} mt={2} className={classes.row}>
                            <Box mr={1}>
                                <Button variant="contained" color="primary" type="submit">
                                    <Text type="small1" color="white">
                                        ENREGISTRER
                                    </Text>
                                </Button>
                            </Box>
                            <Box>
                                <Button onClick={toggleOpen} type="button">
                                    <Text type="small1">
                                    ANNULER
                                    </Text>
                                </Button>
                            </Box>

                        </Box>
                    </form>
                </div>
            </Dialog>
        </>
    );
};