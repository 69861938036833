import {createStyles, fade, InputBase, Theme} from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";

const Input = withStyles((theme: Theme) =>
    createStyles({
        input: {
            borderRadius: '4px!important',
            position: 'relative',
            fontSize: 14,
            color: '#52575C',
            backgroundColor: theme.palette.common.white,
            border: '1px solid #ced4da',
            width: '100%',
            padding: '7px',
            height: '24px',
            transition: theme.transitions.create(['border-color', 'box-shadow']),
            '&:focus': {
                boxShadow: `${fade(theme.palette.primary.main, 0.25)} 0 0 0 0.1rem`,
                borderColor: theme.palette.primary.light,
                borderRadius: 4,
            },
        },
    }),
)(InputBase);

export default Input;
