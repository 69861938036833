import {createMuiTheme, responsiveFontSizes} from "@material-ui/core/styles";

import typography from "./typography";
import palette from "./palette";

require('./moduleup/typography');
require('./moduleup/palette');

let theme = createMuiTheme({
    palette,
    typography,
    overrides: {
        MuiDialog: {
            paper: {
                margin: '0!important',
            }
        },
        MuiChip: {
            root: {
                filter: 'contrast(100%)'
            }
        },
        MuiSwitch: {
            root: {
               marginLeft: '-12px'
            }
        },
        MuiButton: {
            root: {
                height: '36px'
            }
        },
        MuiSelect: {
            select: {
                height: '20px!important',
                paddingTop: '8px!important',
                paddingBottom: '8px!important',
            },
            root: {
               
                
            }
        },
        MuiCheckbox: {
            colorSecondary: {
                color: palette.primary.light,
                '&$checked': {
                    color: palette.primary.main,
                },
            },
        },
    }
});

//theme = responsiveFontSizes(theme);


export default theme;