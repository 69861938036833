import axios from "axios";
import store from "./store";
import {API_URL} from "../config";

const instance = axios.create({
    baseURL: API_URL
});


instance.interceptors.request.use(config => {
    config.headers.authorization = store.jwt || '';
    return config;
}, error => {
    return Promise.reject(error);
});

instance.interceptors.response.use(response => {
    if (response.status === 403) {
        store.disconnect();
        return Promise.reject("403 forbidden");
    }
    return response;
}, error => {
    if (error.response.status === 403) {
        store.disconnect();
    }
    return Promise.reject(error);
});


export const fetcher = url => instance.get(url).then(res => res.data);

export default instance;