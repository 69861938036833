import { Avatar, Box, Button, Card, FormControl, makeStyles } from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react';
import Input from '../components/basics/Input';
import Text from '../components/basics/Text';
import axios from '../providers/axios';
import { useSnackbar } from "notistack";
import store from '../providers/store';
import useGlobalStyle from '../hooks/styles/useGlobalStyle';

const useStyles = makeStyles(theme => ({
    subtitle: {
        color: '#1F2836!important'
    },
    checkIcon: {
        backgroundColor: theme.palette.success.main
    },
    cardContent: {
        padding: theme.spacing(2)
    }
}));

export default (props) => {
    const classes = {
        ...useStyles(),
        ...useGlobalStyle()
    };

    const formRef = useRef(null);
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    function refreshForm() {
        let interval = setInterval(() => {
            if ((formRef && formRef.current)) {
                if (store.auth) {
                    for (const property in store.auth) {
                        if (formRef.current[property]) {
                            formRef.current[property].value = store.auth[property];
                        }
                    }
                }
                clearInterval(interval);
            }
        }, 50);

        return () => {
            clearInterval(interval)
        };
    }

    useEffect(() => {
        const refresh = refreshForm();
        return refresh;
    }, []);


    async function update(event) {
        event.preventDefault();
        const formData = new FormData(event.target);
        let data = {
            firstname: '',
            lastname: '',
            email: '',
            password: '',
            company: '',
            phone: ''
        };
        for (const property in data) {
            data[property] = formData.get(property);
        }
        
        if (
            data['phone'] && (
                typeof data['phone'] !== 'string' ||
                data['phone'].length > 15 ||
                data['phone'].match(new RegExp('[a-zA-Z]'))
            )
        ) {
            enqueueSnackbar('Numéro de téléphone incorrect', { variant: 'error' });
        } else {
            try {
                const result = await axios.put('profile', data);
                store.setAuth(result.data);            
                enqueueSnackbar(`Profil modifié avec succès !`, { variant: 'success' });
    
            } catch (err) {
                enqueueSnackbar(`Une erreur est survenue`, { variant: 'error' });
            }
        }
        

    }

    return (
        <Card className={classes.card}>
            <Box className={classes.cardContent}>
                <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Text type="h3">
                        {store.auth.firstname} {store.auth.lastname}
                    </Text>

                </Box>
                <Box mt={.5}>
                    <Text type="small1" className={classes.subtitle}>
                        Informations personnelles
                    </Text>
                </Box>
                <form ref={formRef} onSubmit={update}>
                    <Box mb={2} mt={1}>

                        <FormControl fullWidth>
                            <Box mt={2} mb={.3}>
                                <label htmlFor="email">
                                    <Text type="subtitle2">
                                        Adresse email
                                </Text>
                                </label>
                            </Box>
                            <Input type="email" required fullWidth id="email" name="email" />
                        </FormControl>
                        <FormControl fullWidth>
                            <Box mt={2} mb={.3}>
                                <label htmlFor="password">
                                    <Text type="subtitle2">
                                        Mot de passe
                                    </Text>
                                </label>
                            </Box>

                            <Input type="password" fullWidth id="password" name="password" />
                        </FormControl>

                        <Box display="flex" alignItems="center" justifyContent="space-between" >
                            <Box width="49%">
                                <FormControl fullWidth>
                                    <Box mt={2} mb={.3}>
                                        <label htmlFor="firstname">
                                            <Text type="subtitle2">
                                                Prénom
                                        </Text>
                                        </label>
                                    </Box>

                                    <Input required fullWidth id="firstname" name="firstname" />
                                </FormControl>
                            </Box>
                            <Box width="49%">
                                <FormControl fullWidth>
                                    <Box mt={2} mb={.3}>
                                        <label htmlFor="lastname">
                                            <Text type="subtitle2">
                                                Nom de famille
                                        </Text>
                                        </label>
                                    </Box>

                                    <Input required fullWidth id="lastname" name="lastname" />
                                </FormControl>
                            </Box>
                        </Box>
                        <Box display="flex" alignItems="center" justifyContent="space-between" >
                            <Box width="49%">
                                <FormControl fullWidth>
                                    <Box mt={2} mb={.3}>
                                        <label htmlFor="company">
                                            <Text type="subtitle2">
                                                Entreprise
                                    </Text>
                                        </label>
                                    </Box>

                                    <Input required fullWidth id="company" name="company" />
                                </FormControl>
                            </Box>
                            <Box width="49%">
                                <FormControl fullWidth>
                                    <Box mt={2} mb={.3}>
                                        <label htmlFor="phone">
                                            <Text type="body2">
                                                Téléphone
                                                    </Text>
                                        </label>
                                    </Box>
                                    <Input fullWidth type="text" id="phone" name="phone" />
                                </FormControl>
                            </Box>
                        </Box>



                    </Box>


                    <Button type="submit" variant="contained" color="primary" fullWidth>
                        <Text type="small1" color="white">
                            Modifier mon profil
                        </Text>
                    </Button>

                </form>
            </Box>


        </Card>


    );
};