import React, { useEffect, useState } from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Text from "../../components/basics/Text";
import Button from "@material-ui/core/Button";
import Pagination from '@material-ui/lab/Pagination';
import OrderIcon from "../../components/icons/OrderIcon";
import Box from "@material-ui/core/Box";
import useSWR from "swr";
import { fetcher } from "../../providers/axios";
import { Checkbox, Popper, useMediaQuery, useTheme } from "@material-ui/core";
import MuiSelect from "@material-ui/core/Select";
import Input from "../../components/basics/Input";
import MenuItem from "@material-ui/core/MenuItem";
import NewMessage from "../../components/NewMessage";
import MailReadIcon from "../../components/icons/MailReadIcon";
import MailNotReadIcon from "../../components/icons/MailNotReadIcon";
import Divider from "@material-ui/core/Divider";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import MenuList from "@material-ui/core/MenuList";
import Fab from "@material-ui/core/Fab";
import { IoChevronBackOutline } from "react-icons/all";
import ArrowResponseIcon from "../../components/icons/ArrowResponseIcon";
import { useSnackbar } from "notistack";
import { useParams } from 'react-router-dom';
import ConversationV2 from "../../components/ConversationV2";
import useGlobalStyle from "../../hooks/styles/useGlobalStyle";
const { htmlToText } = require('html-to-text');


const useStyles = makeStyles(theme => ({
    card: {
        marginBottom: theme.spacing(2),
        background: '#FFFFFF',
        boxShadow: '0px 12px 26px rgba(16, 30, 115, 0.06)',
        borderRadius: '8px',
        minHeight: '30vh',
        overflowY: 'hidden',
        [theme.breakpoints.down('xs')]: {
            minHeight: 0,
            boxShadow: 'none'
        },
    },
    paginator: {
        '&  button': {
            border: '1px solid #E8E8E8'
        },
    },
    boxLine: {
        paddingTop: '12px',
        paddingBottom: '12px',
        paddingRight: '9px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    orderButton: {
        justifySelf: 'flex-end',

        paddingRight: '2px',
        paddingLeft: '2px',
        minWidth: 0,
        '& .MuiButton-label': {
            justifyContent: 'flex-end',
        }
    },
    actionsButtonsGroup: {
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',
        '& > *': {
            margin: theme.spacing(.5),
            [theme.breakpoints.down('xs')]: {
                margin: theme.spacing(1),
            }
            
        }

    },
    conversation: {
        width: '49%',
        position: 'relative',
        [theme.breakpoints.down('sm')]: {
            width: '100%'
        }
    },
    fab: {
        position: 'fixed',
        zIndex: 1000,
        bottom: 10,
        right: 10,
        [theme.breakpoints.up('sm')]: {
            display: 'none'
        }
    },
    mr: {
        marginRight: theme.spacing(8),
        [theme.breakpoints.down('md')]: {
            marginRight: theme.spacing(6),
        },
        [theme.breakpoints.down('sm')]: {
            marginRight: theme.spacing(4),
        },
        [theme.breakpoints.down('xs')]: {
            marginRight: theme.spacing(2),
        }
    },
    name: {
        width: '150px',
        [theme.breakpoints.down('sm')]: {
            width: '50px'
        }
    }
}));

function GroupedActionsInputs({classes, setIdActionChoose, idActionChoose, groupedActions, selectedMessages}) {
    return (
        <Box className={classes.actionsButtonsGroup}>
            <MuiSelect
                displayEmpty
                input={<Input />}
                renderValue={(selected) => selected ? groupedActions.find(ga => ga.id == selected).name : 'Actions groupées'}
                value={idActionChoose}
                onChange={(e) => setIdActionChoose(e.target.value as string)}
            >
                <MenuItem>
                    Actions groupées
                </MenuItem>
                {groupedActions.map((action) => (
                    <MenuItem key={action.id} value={action.id}>
                        {action.name}
                    </MenuItem>
                ))}
            </MuiSelect>


            <Button variant="contained" color="primary"
                disabled={!idActionChoose || !selectedMessages.length} onClick={e => {
                    groupedActions.find(g => g.id == idActionChoose).func();
                }} >
                <Text type="small1" color="white">
                    Appliquer
                </Text>
            </Button>
        </Box>
    )
}

export default ({ getGroupedActions, getUrl, displayStatusResponded, title }) => {
    const classes = {
        ...useGlobalStyle(),
        ...useStyles(),
    };



    const { conversationId, messageId } = useParams();

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
    const isSmallDevice = useMediaQuery(theme.breakpoints.down('sm'));

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const [page, setPage] = useState(0);
    const [messageToDisplay, setMessageTOdisplay] = useState(null);

    useEffect(() => {
        if (conversationId && messageId) {
            setMessageTOdisplay({
                id: messageId,
                conversation: {
                    id: conversationId
                }
            })
        }
    }, []);

    const [openOrder, setOpenOrder] = React.useState(false);
    const anchorOrderRef = React.useRef<HTMLButtonElement>(null);
    const [dateOrder, setDateOrder] = useState('DESC');
    const [idActionChoose, setIdActionChoose] = useState('');
    const [selectedMessages, setSelectedMessages] = useState([]);
    const [allSelectedChecked, setAllSelectedChecked] = useState(false);

    function addOrRemoveMessageFromSelection(message, event = null) {
        event && event.stopPropagation();
        if (selectedMessages.some(m => m.id == message.id)) {
            setSelectedMessages(selectedMessages.filter(m => m.id != message.id));
        } else {
            setSelectedMessages([...selectedMessages, message]);
        }
    }

    function addOrRemoveAllMessagesFromSelection(event = null) {
        event && event.stopPropagation();
        if (!allSelectedChecked) {
            setSelectedMessages(messages);
        } else {
            setSelectedMessages([]);
        }
        setAllSelectedChecked(!allSelectedChecked);
    }

    const numberOfResult = 10;

    const { data, mutate } = useSWR(getUrl({ page, numberOfResult, dateOrder }), fetcher, {
        revalidateOnFocus: false
    });

    const styleName = messageToDisplay ? {width: '50px'} : {};





    const messages = data && data.results;
    const totalMessages = data && data.total;
    const numberOfPages = Math.ceil(totalMessages / numberOfResult);

    const today = new Date();


    const groupedActions = getGroupedActions({ enqueueSnackbar, selectedMessages, setAllSelectedChecked, setSelectedMessages, mutate, setMessageTOdisplay });

    return (
        <React.Fragment>
            <Box mt={3} mb={2} className={classes.pageHead}>
                <Text type="h2">
                    {title}
                </Text>
                {isMobile ? (
                    <Box>
                        <NewMessage>
                            <Fab color="primary" className={classes.fab}>
                                <Text type="h3" color="white">
                                    <span style={{ color: 'white' }}>
                                        +
                                </span>
                                </Text>
                            </Fab>
                        </NewMessage>

                    </Box>
                ) : (
                    <Box>
                        <NewMessage>
                            <Button variant="contained" color="primary">
                                <Text type="small1" color="white">
                                    + NOUVEAU MESSAGE
                            </Text>
                            </Button>
                        </NewMessage>
                    </Box>
                )}


            </Box>
            <div className={classes.card}>
                {isMobile && !messageToDisplay && <GroupedActionsInputs {...({classes, setIdActionChoose, idActionChoose, groupedActions, selectedMessages})} />}
                {(!isSmallDevice || !messageToDisplay) && (
                    <Box className={classes.boxLine}>
                        <Box display="flex" alignItems="center">
                            <Checkbox checked={allSelectedChecked} onClick={addOrRemoveAllMessagesFromSelection} />
                            <Box mr={2}>
                                <Text type="small1" component="span">
                                    Selectionner tout
                                </Text>
                            </Box>
                            {!isMobile && <GroupedActionsInputs {...({classes, setIdActionChoose, idActionChoose, groupedActions, selectedMessages})} />}

                        </Box>


                        <Button className={classes.orderButton} ref={anchorOrderRef} onClick={e => setOpenOrder(true)}>
                            <Box mr={.3}>
                                <OrderIcon />
                            </Box>

                            <Text type="small1">
                                Tri
                        </Text>
                            <Popper open={openOrder} anchorEl={anchorOrderRef.current} role={undefined} transition
                                disablePortal style={{ zIndex: 1000 }}>
                                {({ TransitionProps, placement }) => (
                                    <Grow
                                        {...TransitionProps}
                                        style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                                    >
                                        <Paper>
                                            <ClickAwayListener onClickAway={e => setOpenOrder(false)}>
                                                <MenuList>
                                                    <MenuItem onClick={e => {
                                                        setOpenOrder(false);
                                                        setDateOrder('DESC');
                                                    }}>Plus récents</MenuItem>
                                                    <MenuItem onClick={e => {
                                                        setOpenOrder(false);
                                                        setDateOrder('ASC');
                                                    }}>Plus anciens</MenuItem>
                                                </MenuList>
                                            </ClickAwayListener>
                                        </Paper>
                                    </Grow>
                                )}
                            </Popper>
                        </Button>

                    </Box>
                )}


                {(!isSmallDevice || !messageToDisplay) && <Divider />}



                <Box display={messageToDisplay ? 'flex' : 'block'} maxHeight="70vh">
                    {(!isSmallDevice || !messageToDisplay) && (
                        <Box width={messageToDisplay ? '50%' : '100%'}>
                            {(data && data.results ? data.results : []).map((message) => (
                                <Box key={message.id}>
                                    <Box
                                        className={classes.boxLine}
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => {
                                            setMessageTOdisplay(message)
                                        }}
                                    >

                                        <Box display="flex" alignItems="center">
                                            <Box className={classes.mr}>
                                                <Checkbox
                                                    checked={selectedMessages.some(m => m.id == message.id)}
                                                    onClick={e => addOrRemoveMessageFromSelection(message, e)}
                                                />
                                            </Box>
                                            <Box className={classes.mr} display="flex" alignItems="center">
                                                {(message.read || !displayStatusResponded) ? (
                                                    (message.responded|| !displayStatusResponded) ? <Box component="span" title="Répondu">
                                                        <ArrowResponseIcon style={{
                                                            position: 'absolute',
                                                            transform: 'translate(-20%, -20%)'
                                                        }} />
                                                        <MailReadIcon />
                                                    </Box> : <MailReadIcon />
                                                ) : (
                                                    <MailNotReadIcon />
                                                )}
                                            </Box>
                                            <Box className={classes.mr + ' ' + classes.name} display="flex" alignItems="center" style={styleName}>
                                                {message.sender && (
                                                    (message.read || !displayStatusResponded) ? (
                                                        <Text type="subtitle2" >
                                                            {message.sender.firstname} {message.sender.lastname}
                                                        </Text>
                                                    ) : (
                                                        <Text type="subtitle2" color="primary">
                                                            {message.sender.firstname} {message.sender.lastname}
                                                        </Text>
                                                    )
                                                )}
                                            </Box>
                                            <Box>
                                                <Box>

                                                    <Text type="subtitle2" color={(message.read || !displayStatusResponded) ? 'initial' : 'primary'}>
                                                        {message.conversation.object}
                                                    </Text>
                                                </Box>
                                                <Box>
                                                    <Text type="body2" color={(message.read || !displayStatusResponded) ? 'initial' : 'primary'}>
                                                        {(() => {
                                                            let messageText = htmlToText(message.message);
                                                            if (messageToDisplay || isSmallDevice) {
                                                                if (messageText.length > 20) {
                                                                    messageText = messageText.substring(0, 20) + '...';
                                                                }
                                                            } else {
                                                                if (messageText.length > 90) {
                                                                    messageText = messageText.substring(0, 90) + '...';
                                                                }
                                                            }

                                                            return messageText;
                                                        })()}
                                                    </Text>
                                                </Box>
                                            </Box>
                                        </Box>
                                        <Box>
                                            <Text type="body2" color={message.read || !displayStatusResponded ? 'initial' : 'primary'}>
                                                {(() => {
                                                    let date = new Date(message.dateSend);
                                                    if (today.toLocaleDateString() === date.toLocaleDateString()) {
                                                        return date.toLocaleTimeString().substring(0, 5);
                                                    }
                                                    return date.toLocaleDateString();
                                                })()}
                                            </Text>
                                        </Box>
                                    </Box>

                                    <Divider />
                                </Box>

                            ))}
                            <Box className={classes.boxLine}>
                                <Pagination onChange={(e, value) => setPage(value - 1)} className={classes.paginator}
                                    color="primary" count={numberOfPages} shape="rounded" />
                            </Box>
                        </Box>
                    )}

                    {!isSmallDevice && <Divider orientation="vertical" flexItem />}
                    {messageToDisplay && (
                        <Box className={classes.conversation} style={{overflow: 'auto'}}>


                            <Box pr={1} pl={1} pt={1} pb={1}>
                                {isSmallDevice && (
                                    <Button variant="outlined" onClick={e => { setMessageTOdisplay(null) }}>
                                        <Box display="flex" alignItems="center">
                                            <IoChevronBackOutline fontSize={14} />
                                        </Box>

                                    </Button>
                                )}


                                <ConversationV2 mutateMessages={mutate} messageId={messageToDisplay.id} id={messageToDisplay.conversation.id} />
                            </Box>
                        </Box>
                    )}
                </Box>


            </div>


        </React.Fragment>
    );
};