import Dialog from "@material-ui/core/Dialog";
import Box from "@material-ui/core/Box";
import ErrorIcon from "./icons/ErrorIcon";
import Text from "./basics/Text";
import Button from "@material-ui/core/Button";
import React, { useState } from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import axios, { fetcher } from '../providers/axios';
import { useSnackbar } from "notistack";
import { List, ListItem, MenuItem } from "@material-ui/core";
import useSWR from "swr";
import Input from "./basics/Input";
import MuiSelect from "@material-ui/core/Select";

interface IMoveFilesProps {
    files: Array<any>,
    mutate: Function,
    toggleOpen: any,
    open: boolean,
    setFilesSelected?: Function
}

const useStyle = makeStyles(theme => ({
    row: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'baseline'
    },
    rowButtons: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center'
    },
    dialog: {
        padding: '15px'
    },
    content: {
        padding: theme.spacing(2),
        minWidth: '25vw'
    },
    closeBox: {
        color: '#9FA2B4',
        fontWeight: 400,
        fontSize: '24px',
        letterSpacing: '0.2px'
    },
    button: {
        paddingTop: '15px',
        paddingBottom: '15px',
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-start'
    }
}));

export default (props: IMoveFilesProps) => {
    const classes = useStyle();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const { open, toggleOpen, mutate, files } = props;

    const [folderChoose, setFolderChoose] = React.useState('');


    const { data: inCacheFolders } = useSWR('files', fetcher, {
        revalidateOnFocus: false,
        revalidateOnMount: false,
        revalidateOnReconnect: false,
    });

    const { data: folders } = useSWR('files', fetcher, {
        revalidateOnFocus: false,
        revalidateOnMount: !inCacheFolders,
        revalidateOnReconnect: false,
        initialData: inCacheFolders
    });

    async function confirmMove() {
        try {
            if (Array.isArray(files)) {

                for (const file of files) {
                    try {
                        await axios.put(`files/${file.id}`, {parentId: folderChoose});
                        enqueueSnackbar(`Fichier "${file.name}" déplacé avec succès !`, { variant: 'success' });
                        mutate()
                    } catch (err) {
                        enqueueSnackbar(`Erreur lors du déplacement du fichier "${file.name}`, { variant: 'error'});
                    }
                }

            
                if (typeof props.setFilesSelected === 'function') {
                    props.setFilesSelected([]);
                }
                mutate();
            }

        } catch (err) {
            console.log(err);
            enqueueSnackbar('Erreur lors du déplacement du/des fichier(s)', { variant: 'error' });

        }
        toggleOpen();
    }

    return (
        <Dialog onClose={toggleOpen} className={classes.dialog} open={open}>
            <div className={classes.content}>
                <Box mb={1} className={classes.rowButtons}>
                    <Box component="span" style={{ cursor: 'pointer' }} onClick={toggleOpen}>
                        <span className={classes.closeBox}>X</span>
                    </Box>
                </Box>
                <Box mb={1} mt={1} className={classes.row}>

                    <Box ml={1}>
                        <Text type="h6">
                            Déplacer fichier(s)
                        </Text>
                    </Box>
                </Box>
                <Box mb={1} mt={1} >
                    <List>
                        {(files || []).map(file => (
                            <ListItem key={file.id}>
                                <Text type="body2">
                                    {file.name}
                                </Text>
                            </ListItem>

                        ))}
                    </List>
                    <MuiSelect
                        displayEmpty
                        input={<Input />}
                        renderValue={(selected) => selected ? ((folders || []).find(f => f.id == selected) || {name: ''}).name : 'Dossier'}
                        value={folderChoose}
                        onChange={(e) => setFolderChoose(e.target.value as string)}
                    >
                    
                        {(folders || []).map((folder) => (
                            <MenuItem key={folder.id} value={folder.id}>
                                {folder.name}
                            </MenuItem>
                        ))}
                    </MuiSelect>


                </Box>
                <Box mb={1} mt={2} className={classes.rowButtons}>
                    <Box mr={1}>
                        <Button onClick={toggleOpen}>
                            <Text type="small1">
                                Annuler
                            </Text>
                        </Button>
                    </Box>
                    <Button variant="contained" color="primary" onClick={confirmMove} disabled={!folderChoose}>
                        <Text type="small1" color="white">
                            Ok
                        </Text>
                    </Button>
                </Box>


            </div>


        </Dialog>

    );
};

