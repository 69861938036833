import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import {createStyles, fade, InputBase, Theme} from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";


const useStyles = makeStyles((theme: Theme) => ({
        select: {
            borderRadius: 4,
            position: 'relative',
            fontSize: 15,
            color: '#676879!important',
            backgroundColor: theme.palette.common.white,
            border: '1px solid #ced4da',
            width: '100%',
            padding: '10px 12px',
            transition: theme.transitions.create(['border-color', 'box-shadow']),
            '&:focus': {
                boxShadow: `${fade(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
                borderColor: theme.palette.primary.light,
            },
        },
    })
);

export default (props) => {
    const classes = useStyles();
    return <select {...props} className={classes.select}/>
}

