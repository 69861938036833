import socketIOClient from "socket.io-client";
import store from "./store";
import { mutate } from 'swr'
import { API_URL } from "../config";
import { mutateMany } from 'swr-mutate-many';


let socketStarted = false;

function startSocket() {
    if (!socketStarted) {

        const socket = socketIOClient(API_URL, {
            host: '*',
            extraHeaders: {
                authorization: store.jwt
            }
        });


        socket.on('newNotification', (notification) => {
            if (notification.type === 'newMessage' && store.currentRoute === '/messages/received') {
                mutateMany('message/received*');
            }

            mutate('notifications', notifications => {
                if (Array.isArray(notifications)) {
                    if (notifications.length > 9) {
                        let newNotificationsList = [...notifications];
                        newNotificationsList.splice(-1, 1);
                        newNotificationsList = [notification, ...newNotificationsList];
                        return newNotificationsList;
                    } else {
                        return [notification, ...notifications];
                    }
                } else {
                    return notifications
                }
            }, false);
        })
        socketStarted = true;
    }

}

export default startSocket;