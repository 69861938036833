import React, {useEffect, useRef, useState} from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Dialog from "@material-ui/core/Dialog";
import Box from "@material-ui/core/Box";
import ErrorIcon from "./icons/ErrorIcon";
import Text from "./basics/Text";
import Button from "@material-ui/core/Button";
import {Grid} from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import Input from "./basics/Input";
import Select from "./basics/Select";
import axios from '../providers/axios';
import {useSnackbar} from "notistack";
import FileAttachmentInput from "./basics/FileAttachmentInput";

interface IEditFileProps {
    file?: any,
    mutate: Function,
    folderId?: string | number,
    ButtonProps?: any,
    toggleOpen: any,
    open: boolean
}

const useStyle = makeStyles(theme => ({
    row: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'baseline'
    },
    rowButtons: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center'
    },
    dialog: {
        padding: '15px',
        '& .MuiPaper-root': {
            minWidth: '40%'

        }
        
    },
    content: {
        padding: theme.spacing(2),

        
    },
    closeBox: {
        color: '#9FA2B4',
        fontWeight: 400,
        fontSize: '24px',
        letterSpacing: '0.2px'
    },
    inputFile: {}
}));

export default (props: IEditFileProps) => {
    const classes = useStyle();
    const formRef = useRef(null);
    const [selectedFile, setSelectedFile] = useState(undefined);
    const [name, setName] = useState('');
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const isUpdate = props.file && props.file.id;

    function toggleOpen(event = null) {
        event && event.stopPropagation && event.stopPropagation();
        if (props.file) {
            setName(props.file.name);
        } else {
            setSelectedFile(undefined);
            setName('');
        }
        props.toggleOpen();
    }

    useEffect(() => {
        if (isUpdate) {
            setName(props.file.name);
        }
    }, []);

    const edit = async event => {
        event.preventDefault();
        if (isUpdate) {
            try {
                const file = (await axios.put(`files/${props.file.id}`, {name})).data;
                props.mutate();
                enqueueSnackbar('Fichier enregistré avec succès !', {variant: 'success'});
            } catch (err) {
                console.log(err);
                enqueueSnackbar('Erreur lors de l\'enregistrement du fichier', {variant: 'error'});
            }
        } else {
            upload(event);
        }
        toggleOpen();
    }

    const upload = async event => {
        event.preventDefault();
        try {
            let currentFile = selectedFile[0];
            Object.defineProperty(currentFile, 'name', {
                writable: true,
                value: name
            });
            let formData = new FormData();
            formData.append("file", currentFile);
            formData.append('name', name);
            const newFile = (await axios.post(`files/${props.folderId}/upload/`, formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                }
            })).data;
            props.mutate();
            enqueueSnackbar('Fichier enregistré avec succès !', {variant: 'success'});
        } catch (err) {
            console.error(err);
            enqueueSnackbar('Erreur lors de l\'enregistrement du fichier', {variant: 'error'});
        }
    };

    const chooseFile = event => {
        setName(event.target.files[0].name);
        setSelectedFile(event.target.files);
    };

    return (
        <>
            <Dialog onClose={toggleOpen} className={classes.dialog} open={props.open}>
                <div className={classes.content} onClick={e => e.stopPropagation()}>
                    <Box mb={.1} className={classes.rowButtons}>
                        <Box component="span" style={{cursor: 'pointer'}} onClick={toggleOpen}>
                            <span className={classes.closeBox}>X</span>
                        </Box>
                    </Box>
                    {props.file && props.file.id ? (
                        <Text type="h3">
                            Modifier "{props.file.name}"
                        </Text>
                    ) : (
                        <Text type="h3">
                            Nouveau fichier
                        </Text>
                    )}
                    <form onSubmit={edit} ref={formRef}>
                        <Box mb={1} mt={1}>
                            <Grid container>
                                <Grid item xs={12} sm={12}>
                                    <Box mr={2}>
                                        <FormControl fullWidth>
                                            <Box mt={2} mb={.3}>
                                                <label htmlFor="name">
                                                    <Text type="body2">
                                                        Nom
                                                    </Text>
                                                </label>
                                            </Box>
                                            <Input value={name} onChange={e => setName(e.target.value)} required
                                                   fullWidth id="name" name="name"/>
                                        </FormControl>
                                        {!isUpdate && (
                                            <FormControl fullWidth>
                                                <Box mt={2} mb={.3}>
                                                    <label htmlFor="name">
                                                        <Text type="body2">
                                                            Fichier
                                                        </Text>
                                                    </label>
                                                </Box>
                                                {/*
                                                <Input className={classes.inputFile} type="file" onChange={chooseFile}/>
                                                */}
                                                <FileAttachmentInput initialText="Selectionnez un fichier" multiple={false}  onChange={chooseFile} />
                                            </FormControl>
                                        )}

                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                        <Box mb={1} mt={2} className={classes.row}>
                            <Box mr={1}>
                                <Button variant="contained" color="primary" type="submit">
                                    <Text type="small1" color="white">
                                        ENREGISTRER
                                    </Text>
                                </Button>
                            </Box>
                            <Box>
                                <Button onClick={toggleOpen} type="button">
                                    <Text type="small1">
                                    ANNULER
                                    </Text>
                                </Button>
                            </Box>

                        </Box>
                    </form>
                </div>
            </Dialog>
        </>
    );
};