import React, { useState } from "react";
import { Card } from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import logo from '../assets/img/logo.svg';
import Input from "../components/basics/Input";
import axios from "../providers/axios";
import store from "../providers/store";
import Button from "@material-ui/core/Button";
import '../theme'
import Text from "../components/basics/Text";
import FormControl from '@material-ui/core/FormControl';
import Box from "@material-ui/core/Box";
import { useHistory, useParams } from 'react-router-dom';
import { useSnackbar } from "notistack";


const useStyles = makeStyles(theme => ({
    card: {
        position: 'absolute',
        left: '50%',
        top: '100px',
        transform: 'translate(-50%, 0)',
        width: '598px',
        background: '#FFFFFF',
        border: '1px solid #E0E0E0',
        boxSizing: 'border-box',
        borderRadius: '10px',
        padding: '55px',
        boxShadow: 'none',
        overflow: 'auto',
        [theme.breakpoints.down('xs')]: {
            width: '100%',
            height: '100%',
            top: 0
        },
    },
    logo: {
        position: 'relative',
        left: '50%',
        transform: 'translateX(-50%)',
        marginBottom: '25px',
        width: '146px'
    },
    textGray: {
        color: '#828282',
        marginBottom: '15px'
    },
    createAccountBtn: {
        backgroundColor: theme.palette.success.main,
        '&:hover': {
            backgroundColor: theme.palette.success.dark
        }
    }
}));

export default (props) => {
    const classes = useStyles();

    const { securekey } = useParams();
    const history = useHistory();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    console.log({securekey})


    const updatePassword = async (event) => {
        event.preventDefault();
        const formData = new FormData(event.target);
        const password = formData.get('password');
        try {
            const result = await axios.put(`users?securekey=${securekey}`, { password });
            if (result.data) {
                store.setAuth(result.data.user, result.data.token);
                enqueueSnackbar(`Mot de passe réinitialisé !`, { variant: 'success' });
                history.push('/');
                document.location.reload();
            }
        } catch (err) {
            enqueueSnackbar(`Une erreur est survenu`, { variant: 'error' });
        }
    };


    return (
        <Card className={classes.card}>
            <>

                <img className={classes.logo} src={logo} title="logo" alt="logo" />
                <form onSubmit={updatePassword}>

                    <FormControl fullWidth>
                        <Box mt={2} mb={.3}>
                            <label htmlFor="password">
                                <Text type="subtitle2">
                                    Nouveau mot de passe
                                </Text>
                            </label>
                        </Box>

                        <Input type="password" required fullWidth id="password" name="password" />
                    </FormControl>

                    <Box mt={1}>
                        <Button type="submit" variant="contained" color="primary" fullWidth>
                            <Text type="small1" color="white">
                                Je change mon mot de passe
                            </Text>
                        </Button>
                    </Box>

                </form>
            </>
        </Card>
    );
};