import { Box, Button } from '@material-ui/core';
import React, { useRef, useState } from 'react';
import { ChangeEventHandler } from 'react';
import AttachFileIcon from '../icons/AttachFileIcon';
import Input from './Input';
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles(theme => ({
    attachment: {
        color: '#ced4da',
        borderColor: '#ced4da',
        fontWeight: 'normal',
        fontSize: 15,
        '& .MuiButton-label': {
            justifyContent: 'flex-start'
        }
    }
}))

interface IFileAttachmentInputProps {
    name?: string;
    fullWidth?: boolean;
    onChange?: ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>;
    multiple?: boolean;
    initialText?: string;
}

export default (props: IFileAttachmentInputProps) => {
    const classes = useStyles();
    const ref = useRef(null);
    const [attachments, setAttachments] = useState(null);

    function handleClick() {
        if (ref && ref.current) {
            ref.current.click();
        }
    }

    function onChange(event) {
        setAttachments(event.target.files);
        if (props.onChange) {
            props.onChange(event);
        }
    }


    const inputProps = {
        ...props,
        multiple: props.multiple || true,
        type: "file",
        onChange,
        style: {
            display: 'none'
        }
    }

   
    return (
        <>
            <Button variant="outlined" className={classes.attachment} onClick={handleClick}>
                <Box mr={2} component="span">
                    <AttachFileIcon />
                </Box>
                {!attachments && (
                    props.initialText || "Selectionnez un/des fichier(s)"
                )}
                {attachments && Array.from(attachments).map(a => a['name']).join(', ')}
               
            </Button>
            
            <input {...inputProps} ref={ref} />
            

        </>
    );
}