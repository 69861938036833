import React, { useState } from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Text from "../../components/basics/Text";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import Button from "@material-ui/core/Button";
import Chip from '@material-ui/core/Chip';
import Box from "@material-ui/core/Box";
import EditIcon from "../../components/icons/EditIcon";
import DeleteIcon from "../../components/icons/DeleteIcon";
import useSWR from "swr";
import { fetcher } from "../../providers/axios";
import EditGroup from "../../components/EditGroup";
import DeleteGroup from "../../components/DeleteGroup";
import useGlobalStyle from '../../hooks/styles/useGlobalStyle';
import { getContrastYIQ } from "../../utils";

const useStyles = makeStyles(theme => ({
    paginator: {
        '&  button': {
            border: '1px solid #E8E8E8'
        },
    },
    chip: {
        marginRight: theme.spacing(1),
        border: '1px solid rgba(29, 112, 183, 0.37)'
    },
    textGrey: {
        color: '#A0A4A8',
    }
}));



export default (props) => {
    const classes = {
        ...useStyles(),
        ...useGlobalStyle()
    };


    const { data: dataGroups, mutate: mutateGroups } = useSWR(`groups`, fetcher, {
        revalidateOnFocus: false,
    });

    const groups = dataGroups && dataGroups.results;
    /*
    const totalGroups = dataUsers && dataUsers.total;
    const numberOfPages = Math.ceil(totalUsers / numberOfResult);
     */

    return (
        <React.Fragment>
            <Box mt={3} mb={2} className={classes.pageHead}>
                <Text type="h2">
                    Administration des groupes
                </Text>
                <Box>
                    <EditGroup mutate={mutateGroups}>
                        <Button variant="contained" color="primary">
                            <Text type="small1" color="white">
                                + AJOUTER UN GROUPE
                            </Text>
                        </Button>
                    </EditGroup>
                </Box>
            </Box>
            <div className={classes.card}>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                <Text type="subtitle2">
                                    Groupe
                                </Text>
                            </TableCell>
                            <TableCell>
                                <Text type="subtitle2">
                                    Membres
                                </Text>
                            </TableCell>
                            <TableCell align="right">
                                {/*
                                <Button>
                                    <Box mr={.3}>
                                        <OrderIcon/>
                                    </Box>
                                    <Text type="small1">
                                        Tri
                                    </Text>
                                </Button>
                                <Button>
                                    <Box mr={.3}>
                                        <FilterIcon/>
                                    </Box>
                                    <Text type="small1">
                                        Filtre
                                    </Text>
                                </Button>
                                */}
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {(groups || []).map((group) => (
                            <TableRow key={group.id}>
                                <TableCell component="th" scope="row">
                                    <Chip style={{ background: group.color || '#e0e0e0' }} label={
                                        <Text type="small1">
                                            <span style={{ color: getContrastYIQ(group.color || '#e0e0e0') }}>
                                                {group.name}
                                            </span>
                                        </Text>
                                    } />
                                </TableCell>
                                <TableCell>
                                    {group.users &&
                                        group.users
                                            .filter((u, i) => i < 4)
                                            .map(user => (
                                                <Chip color="primary" variant="outlined" className={classes.chip}
                                                    key={user.id} label={
                                                        <Text type="small1" className={classes.textGrey}>
                                                            {user.firstname + ' ' + user.lastname}
                                                        </Text>
                                                    } />
                                            )
                                            )
                                    }
                                    {group.users && group.users.length > 4 && (
                                        <Text type="small1" component="span" className={classes.textGrey}>
                                            {`+${group.users.length - 4} utilisateurs`}
                                        </Text>
                                    )}
                                </TableCell>
                                <TableCell align="right">

                                    <Box mr={2} component="span" style={{ cursor: "pointer" }}>
                                        <EditGroup group={group} mutate={mutateGroups}>
                                            <EditIcon />
                                        </EditGroup>
                                    </Box>
                                    <Box component="span" style={{ cursor: "pointer" }}>
                                        <DeleteGroup id={group.id} mutate={mutateGroups}>
                                            <DeleteIcon />
                                        </DeleteGroup>
                                    </Box>

                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>

            </div>

        </React.Fragment>
    );
};