import React, { useState, useEffect } from 'react';
import { Box, Button, ClickAwayListener, createStyles, fade, Grow, InputAdornment, InputBase, makeStyles, MenuItem, MenuList, Paper, Popper, TextField, Theme } from "@material-ui/core";
import SearchIcon from '../icons/SearchIcon';
import useSWR from 'swr';
import Text from '../basics/Text';
import { useHistory, Link } from 'react-router-dom';
import { fetcher } from '../../providers/axios';
import NewMessage from '../NewMessage';
import UserSearchIcon from '../icons/UserSearchIcon';
import EyeSearchIcon from '../icons/EyeSearchIcon';
import MailSearchIcon from '../icons/MailSearchIcon';
import store from '../../providers/store';


const useStyles = makeStyles(theme => ({
    paper: {
        //padding: theme.spacing(1)
    },
    boxSearch: {
        position: 'absolute',
        transform: 'translateY(-50%)',
        borderRadius: 27,
        backgroundColor: theme.palette.common.white,
        padding: '10px 12px',
        zIndex: 1000,
        

        width: "425px",
        transition: theme.transitions.create(['width']),
        '&:focus, &:focus-within': {
            width: '475px'
        },
        [theme.breakpoints.down('sm')]: {
            width: "250px",
            '&:focus, &:focus-within': {
                width: '350px'
            },
        },  
    },
    boxSearchContent: {
        backgroundColor: theme.palette.common.white,
        position: 'absolute',
        borderBottomLeftRadius: 27,
        borderBottomRightRadius: 27,
        left: 0,
        width: '100%',
    },
    input: {
        position: 'relative',
        fontSize: 14,
        width: '100%',
        '& .MuiInputBase-input': {
            padding: 0
        },
        '& .MuiInput-underline:before': {
            content: 'none!important'
        },
        '& .MuiInput-underline.Mui-focused:after': {
            transform: 'scaleX(0)'
        }
    },
    icon: {
        border: '1px solid grey',
        borderRadius: '5px',
        padding: '5px'
    },
    seeMore: {
        cursor: 'pointer',
    },
    link: {
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.04)'
        },

    }
}));

function ResultItemsFolder(props) {
    const { items, goTo, classes } = props;

    return (
        <>
            {(Array.isArray(items) && items && items.length) ? (
                <Box display="flex" alignItems="center" justifyContent="space-between" mt={2} mb={2} p={1}>
                    <Box display="flex" alignItems="center">
                        <svg width="12" height="15" viewBox="0 0 12 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M6.5625 3.98438V0H0.703125C0.313477 0 0 0.313477 0 0.703125V14.2969C0 14.6865 0.313477 15 0.703125 15H10.5469C10.9365 15 11.25 14.6865 11.25 14.2969V4.6875H7.26562C6.87891 4.6875 6.5625 4.37109 6.5625 3.98438ZM11.25 3.57129V3.75H7.5V0H7.67871C7.86621 0 8.04492 0.0732422 8.17676 0.205078L11.0449 3.07617C11.1768 3.20801 11.25 3.38672 11.25 3.57129Z" fill="#1D70B7" fillOpacity="0.48" />
                        </svg>

                        <Box ml={1}>
                            <Text type="subtitle2">
                                Dossiers
                        </Text>
                        </Box>
                    </Box>

                    {items.length > 4 && (
                        <Box onClick={e => goTo('/files')} className={classes.seeMore}>
                            <Text type="small3" color="primary">
                                Voir plus
                            </Text>
                        </Box>
                    )}

                </Box>
            ) : ''}

            {
                items && (items || []).map(item => (

                    <Box key={item.id} onClick={e => goTo(`/files/${item.id}`)}>
                        <Box className={classes.link} p={1}>
                            <Box display="flex" justifyContent="space-between" alignItems="center">
                                <Text type="small1">
                                    {item.name}
                                </Text>
                                <EyeSearchIcon />
                            </Box>


                        </Box>
                    </Box>


                ))
            }

        </>
    );
}

function ResultItemsUser(props) {
    const { items, goTo, classes } = props;

    return (
        <>
            {(Array.isArray(items) && items && items.length) ? (
                <Box display="flex" alignItems="center" justifyContent="space-between" mt={2} mb={2} p={1}>
                    <Box display="flex" alignItems="center">
                        <UserSearchIcon />
                        <Box ml={1}>
                            <Text type="subtitle2">
                                Annuaire
                        </Text>
                        </Box>
                    </Box>
                    {items.length > 4 && (
                        <Box onClick={e => goTo('/users')} className={classes.seeMore}>
                            <Text type="small3" color="primary">
                                Voir plus
                            </Text>
                        </Box>
                    )}
                </Box>
            ) : ''}
            {
                items && (items || []).map(item => (
                    <NewMessage key={item.id} users={[item]}>
                        <Box className={classes.link} p={1}>
                            <Text type="small1">
                                <Box display="flex" justifyContent="space-between" alignItems="center">
                                    {item.firstname} {item.lastname}
                                    <MailSearchIcon />
                                </Box>
                            </Text>

                        </Box>
                    </NewMessage>

                ))
            }

        </>

    );
}

export default (props) => {
    const classes = useStyles();
    const [search, setSearch] = useState('');
    const [searchInputValue, setSearchInputValue] = useState('');

    const history = useHistory();


    const { data: results } = useSWR(search ? `search?search=${search}` : null, fetcher);
    const { users, folders } = results || {};

    const open = ((Array.isArray(folders) && folders.length) || (Array.isArray(users) && users.length)) && search.length;


    function goTo(link, event) {
        store.goToLink(link);
        //history.push(link);
        handleClose();
    }


    const handleClose = () => {
        setSearchInputValue('');
        setSearch('');
    };



    useEffect(() => {
        const timeoutSearch = setTimeout(() => {
            setSearch(searchInputValue);
        }, 500);

        return () => { clearTimeout(timeoutSearch) };
    }, [searchInputValue]);


    return (
        <>
            <Box className={classes.boxSearch}>
                <TextField className={classes.input} value={searchInputValue} onChange={e => setSearchInputValue(e.target.value)} placeholder="Rechercher" InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <SearchIcon />
                        </InputAdornment>
                    )
                }} />


                {open ? (
                    <Box className={classes.boxSearchContent}>
                        <ClickAwayListener onClickAway={handleClose}>
                            <Box>
                                <ResultItemsUser classes={classes} items={users} goTo={goTo} />
                                <ResultItemsFolder classes={classes} items={folders} goTo={goTo} />
                            </Box>

                        </ClickAwayListener>
                    </Box>
                ) : ''}


            </Box>
            {/*
            <span ref={anchorRef}>
                <TextField value={searchInputValue} onChange={e => setSearchInputValue(e.target.value)} className={classes.input} placeholder="Rechercher" InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <SearchIcon />
                        </InputAdornment>
                    )
                }} />
            </span>

           
            <Popper open={!!open} anchorEl={anchorRef.current} role={undefined} transition disablePortal style={{ zIndex: 1000 }} placement="bottom">
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}

                    >
                        <Paper className={classes.paper}>
                            <ClickAwayListener onClickAway={handleClose}>
                                <Box>
                                    <ResultItemsFolder classes={classes} items={folders} goTo={goTo} />
                                    <ResultItemsUser classes={classes} items={users} goTo={goTo} />
                                </Box>

                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
            */}

        </>
    )
};


