import React, { useState } from "react";
import { Card, Divider } from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import logo from '../assets/img/logo.svg';
import Input from "../components/basics/Input";
import axios from "../providers/axios";
import store from "../providers/store";
import Button from "@material-ui/core/Button";
import '../theme'
import Text from "../components/basics/Text";
import FormControl from '@material-ui/core/FormControl';
import Box from "@material-ui/core/Box";
import { useHistory } from 'react-router-dom';
import { useSnackbar } from "notistack";
import Register from "./Register";
import ForgetPassword from "./ForgetPassword";

const useStyles = makeStyles(theme => ({
    card: {
        position: 'absolute',
        left: '50%',
        top: '100px',
        transform: 'translate(-50%, 0)',
        width: '598px',
        background: '#FFFFFF',
        border: '1px solid #E0E0E0',
        boxSizing: 'border-box',
        borderRadius: '10px',
        padding: '55px',
        boxShadow: 'none',
        overflow: 'auto',
        [theme.breakpoints.down('xs')]: {
            width: '100%',
            height: '100%',
            top: 0,
            paddingTop: '25px'
        },
    },
    logo: {
        position: 'relative',
        left: '50%',
        transform: 'translateX(-50%)',
        marginBottom: '25px',
        width: '146px'
    },
    textGray: {
        color: '#828282',
        marginBottom: '15px'
    },
    createAccountBtn: {
        backgroundColor: theme.palette.success.main,
        '&:hover': {
            backgroundColor: theme.palette.success.dark
        }
    }
}));

export default (props) => {
    const classes = useStyles();
    const [displayCreateAccount, setDisplayCreateAccount] = useState(false);
    const [displayForgetPassword, setDisplayForgetPassword] = useState(false); 

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();


    const login = async (event) => {
        event.preventDefault();
        const formData = new FormData(event.target);
        const email = formData.get('id');
        const password = formData.get('password');
        try {
            const result = await axios.post('auth/login', { email, password });
            if (result.data) {
                store.setAuth(result.data.user, result.data.token);
                enqueueSnackbar(`Bonjour ${result.data.user.firstname} !`, { variant: 'success' });
                //history.push('/messages/received');
            }
        } catch (err) {
            enqueueSnackbar(`Identifiant ou mot de passe incorect`, { variant: 'error' });
        }
    };


    return (
        <Card className={classes.card}>
            {displayForgetPassword ? (
                <ForgetPassword setDisplayForgetPassword={setDisplayForgetPassword} />
            ) : (
                !displayCreateAccount ? (
                    <>
    
                        <img className={classes.logo} src={logo} title="logo" alt="logo" />
                        <form onSubmit={login}>
                            <FormControl fullWidth>
                                <Box mt={2} mb={.3}>
                                    <label htmlFor="id">
                                        <Text type="subtitle2">
                                            Identifiant
                                        </Text>
                                    </label>
                                </Box>
                                <Input type="email" required fullWidth id="id" name="id" />
                            </FormControl>
                            <FormControl fullWidth>
                                <Box mt={2} mb={.3}>
                                    <label htmlFor="password">
                                        <Text type="subtitle2">
                                            Mot de passe
                                        </Text>
                                    </label>
                                </Box>
    
                                <Input type="password" required fullWidth id="password" name="password" />
                            </FormControl>
                            <Box mt={1} mb={3}>
                                <Box component="span" style={{ cursor: 'pointer' }} onClick={() => setDisplayForgetPassword(true)}>
                                    <Text className={classes.textGray} type="small1">
                                        Mot de passe oublié ?
                                    </Text>
                                </Box>
                            </Box>
                            <Button type="submit" variant="contained" color="primary" fullWidth>
                                <Text type="small1" color="white">
                                    Je me connecte
                                </Text>
                            </Button>
    
                        </form>
    
                        <Box mt={2} mb={2}>
                            <Divider />
                        </Box>
    
                        <Box mb={2} style={{ cursor: 'pointer' }} onClick={e => setDisplayCreateAccount(true)}>
                            <Text className={classes.textGray} type="body2">
                                Vous n’avez pas encore de compte ?
                            </Text>
                        </Box>
    
                        <Button variant="contained" color="primary" fullWidth className={classes.createAccountBtn} onClick={e => setDisplayCreateAccount(true)}>
                            <Text type="small1" color="white">
                                Je crée mon compte
                            </Text>
                        </Button>
                    </>
                ) : <Register setDisplayCreateAccount={setDisplayCreateAccount} />
            )}
            

        </Card>
    );
};