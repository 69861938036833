import React from "react";
import Text from "../basics/Text";
import {Popper} from "@material-ui/core";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import MenuList from "@material-ui/core/MenuList";
import MenuItem from "@material-ui/core/MenuItem";
import {Link} from 'react-router-dom';
import ArrowBottomMenuIcon from "../icons/ArrowBottomMenuIcon";
import {useHistory} from 'react-router-dom';
import Box from "@material-ui/core/Box";
import store from "../../providers/store";
export default ({classes}) => {
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef<HTMLButtonElement>(null);
    const history = useHistory();

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event: React.MouseEvent<EventTarget>) => {
        if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
            return;
        }

        setOpen(false);
    };

    function handleListKeyDown(event: React.KeyboardEvent) {
        if (event.key === 'Tab') {
            event.preventDefault();
            setOpen(false);
        }
    }

    // return focus to the button when we transitioned from !open -> open
    const prevOpen = React.useRef(open);
    React.useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current!.focus();
        }

        prevOpen.current = open;
    }, [open]);

    function goTo(path, event) {
        handleClose(event);
        store.goToLink(path);
    }

    return (
        <React.Fragment>
            <Box  mr={2} ml={2}>
                <span style={{cursor: 'pointer'}}
                      ref={anchorRef}
                      aria-controls={open ? 'menu-list-grow' : undefined}
                      aria-haspopup="true"
                      onClick={handleToggle}
                >
                <Text type="subtitle2" className={`${classes.link} ${store.currentRoute.includes('/admin/users') ||store.currentRoute.includes('/admin/groups') ? 'active' : ''}`}  component="span">
                    Administration
                    <i style={{marginLeft: '5px'}}>
                        <ArrowBottomMenuIcon />
                    </i>
                </Text>

            </span>
            </Box>
            <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal style={{zIndex: 1000}}>
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                                    <MenuItem onClick={e => goTo('/admin/users', e)}>Utilisateurs</MenuItem>
                                    <MenuItem onClick={e => goTo('/admin/groups', e)}>Groupes</MenuItem>
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </React.Fragment>
    );
};