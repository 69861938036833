import React, { useEffect, useState } from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Text from "../../components/basics/Text";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import Button from "@material-ui/core/Button";
import Chip from '@material-ui/core/Chip';
import Pagination from '@material-ui/lab/Pagination';
import Box from "@material-ui/core/Box";
import EditIcon from "../../components/icons/EditIcon";
import DeleteIcon from "../../components/icons/DeleteIcon";
import useSWR from "swr";
import axios, { fetcher } from "../../providers/axios";
import EditUser from "../../components/EditUser";
import DeleteUser from "../../components/DeleteUser";
import FilterUsers from "../../components/FilterUsers";
import { TableSortLabel } from "@material-ui/core";
import useGlobalStyle from '../../hooks/styles/useGlobalStyle';
import { useSnackbar } from "notistack";
import store from "../../providers/store";
import Input from "../../components/basics/Input";
import CustomAutocomplete from "../../components/basics/CustomAutocomplete";
import Switch from '@material-ui/core/Switch';
import Select from "../../components/basics/Select";
import { getContrastYIQ } from "../../utils";


const useStyles = makeStyles(theme => ({
    paginator: {
        '&  button': {
            border: '1px solid #E8E8E8'
        },
    },
    chip: {
        marginRight: theme.spacing(1)
    },
    activeIcon: {
        color: theme.palette.success.main,
    },
    notActiveIcon: {
        color: theme.palette.error.main
    },
    notValidatedIcon: {
        color: theme.palette.warning.main
    }
}));

export default (props) => {
    const classes = {
        ...useStyles(),
        ...useGlobalStyle()
    };

    const [page, setPage] = useState(0);

    const [filterCompany, setFilterCompany] = useState('');
    const [filterName, setFilterName] = useState('');
    const [filterGroups, setFilterGroups] = useState(props.filterGroupsIds || []);
    const [filterRole, setFilterRole] = useState('');
    const [filterIsActive, setFilterIsActive] = useState(false);


    const [filterUrlParams, setFilterUrlParams] = useState(getFilterUrlParams());

    const [orderBy, setOrderBy] = useState('name');
    const [orderDir, setOrderDir] = useState('desc');

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();


    function getOrderByUrlParams(): string {
        if (orderBy) {
            return `&orderBy=${orderBy}&orderDir=${orderDir || 'desc'}`
        }
        return '';
    }

    function getFilterUrlParams(): string {
        let urlParams = '&withInactive=1';
        if (filterName.length) {
            urlParams += `&name=${filterName}`;
        }
        if (filterCompany.length) {
            urlParams += `&company=${filterCompany}`;
        }
        if (filterGroups.length) {
            urlParams += `&groups=${filterGroups.map(group => group.id).join(',')}`;
        }
        if (filterIsActive) {
            urlParams += `&isActive=1`;
        }
        if (filterRole) {
            urlParams += `&role=${filterRole}`;
        }
        return urlParams;
    }

    useEffect(() => {
        const timeout = setTimeout(() => {
            setFilterUrlParams(getFilterUrlParams());
        }, 500);

        return () => { clearTimeout(timeout); };
    }, [filterCompany, filterGroups, filterName, filterRole, filterIsActive]);

    function toggleOrderDir(): void {
        setOrderDir(orderDir === 'desc' ? 'asc' : 'desc');
    }

    function selectColumnForOrder(columName: string) {
        if (columName === orderBy) {
            toggleOrderDir();
        } else {
            setOrderBy(columName);
        }
    }


    const numberOfResult = 10;

    const { data: dataUsers, mutate: mutateUsers } = useSWR(`users?page=${page}&numberOfResult=${numberOfResult}${filterUrlParams}${getOrderByUrlParams()}`, fetcher, {
        revalidateOnFocus: false,
    });
    const { data: dataGroups, mutate: mutateGroups } = useSWR(`groups`, fetcher, {
        revalidateOnFocus: false,
    });

    const groups = dataGroups && dataGroups.results;
    const users = dataUsers && dataUsers.results;
    const totalUsers = dataUsers && dataUsers.total;
    const numberOfPages = Math.ceil(totalUsers / numberOfResult);

    async function toggleActive(user, isActive: boolean) {
        try {
            await axios.put(`users/${user.id}`, { isActive });
            mutateUsers(({ results, total }) => {
                return {
                    results: results.map(u => u.id == user.id ? { ...user, isActive } : u),
                    total
                };
            }, false);
            enqueueSnackbar(`Utilisateur ${user.firstname} ${user.lastname} modifié avec succès !`, { variant: 'success' });
        } catch (err) {
            enqueueSnackbar(`Error lors de la modification de l\'utilisateur ${user.firstname} ${user.lastname}`, { variant: 'error' });
        }
    }


    return (
        <React.Fragment>
            <Box mt={3} mb={2} className={classes.pageHead}>
                <Text type="h2">
                    Administration des utilisateurs
                </Text>
                <Box>
                    <EditUser groups={groups || []} mutate={mutateUsers}>
                        <Button variant="contained" color="primary">
                            <Text color="white" type="small1">
                                + AJOUTER UN UTILISATEUR
                            </Text>
                        </Button>
                    </EditUser>
                </Box>
            </Box>
            <div className={classes.card}>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                <TableSortLabel onClick={e => selectColumnForOrder('name')} active={orderBy === 'name'} direction={orderDir as 'asc' | 'desc'}>
                                    <Text type="subtitle2">
                                        Nom
                                    </Text>
                                </TableSortLabel>
                                <Input required fullWidth value={filterName}
                                    onChange={e => setFilterName(e.target.value)} />

                            </TableCell>
                            <TableCell>
                                <TableSortLabel onClick={e => selectColumnForOrder('company')} active={orderBy === 'company'} direction={orderDir as 'asc' | 'desc'}>
                                    <Text type="subtitle2">
                                        Entreprise
                                    </Text>
                                </TableSortLabel>
                                <Input required fullWidth value={filterCompany}
                                    onChange={e => setFilterCompany(e.target.value)} />
                            </TableCell>
                            <TableCell>
                                <TableSortLabel hideSortIcon>
                                    <Text type="subtitle2">
                                        Groupe(s)
                                    </Text>
                                </TableSortLabel>

                                <CustomAutocomplete AutoCompleteProps={{
                                    options: groups || [],
                                    multiple: true,
                                    value: filterGroups,
                                    onChange: (e, newValue) => setFilterGroups(newValue)
                                }} getOptionLabel={group => group['name']}
                                />
                            </TableCell>
                            <TableCell>
                                <TableSortLabel onClick={e => selectColumnForOrder('role')} active={orderBy === 'role'} direction={orderDir as 'asc' | 'desc'}>
                                    <Text type="subtitle2">
                                        Rôle
                                    </Text>
                                </TableSortLabel>
                                <Select value={filterRole} onChange={e => setFilterRole(e.target.value)}>
                                    <option value="">
                                        Tous
                                    </option>
                                    <option value="admin">
                                        Administrateur
                                    </option>
                                    <option value="user">
                                        Membre
                                    </option>
                                </Select>
                            </TableCell>
                            <TableCell>
                                <TableSortLabel hideSortIcon>
                                    <Text type="subtitle2">
                                        Actif
                                    </Text>
                                </TableSortLabel>
                                <Box height="40px">
                                    <Switch color="primary" onChange={e => setFilterIsActive(e.target.checked)} />
                                </Box>

                            </TableCell>
                            <TableCell align="right">
                                {/*
                                <FilterUsers
                                    groups={groups}
                                    filterGroups={filterGroups}
                                    filterName={filterName}
                                    filterCompany={filterCompany}
                                    onChangeName={setFilterName}
                                    onChangeCompany={setFilterCompany}
                                    onChangeGroups={setFilterGroups}
                                />
                                */}
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {(users || []).filter(u => u.id != store.auth.id).map((user) => (
                            <TableRow key={user.id}>
                                <TableCell >
                                    <Text type="body2">
                                        {user.firstname} {user.lastname}
                                    </Text>
                                </TableCell>
                                <TableCell>
                                    <Text type="body2">
                                        {user.company}
                                    </Text>
                                </TableCell>
                                <TableCell>
                                    {
                                        user.groups
                                            .filter((grp, i) => i < 4)
                                            .map(grp => (
                                                <Chip style={{ background: grp.color || '#e0e0e0' }}
                                                    className={classes.chip} key={grp.id} label={
                                                        <Text type="small1">
                                                            <span style={{ color: getContrastYIQ(grp.color || '#e0e0e0') }}>
                                                                {grp.name}
                                                            </span>
                                                        </Text>
                                                    } />
                                            ))
                                    }
                                    {user.groups.length > 4 && (
                                        <Text type="small1" component="span">
                                            {`+${user.groups.length - 4} groupes`}
                                        </Text>
                                    )}
                                </TableCell>
                                <TableCell>
                                    <Text type="body2">
                                        {user.role === 'admin' ? 'Administrateur' : 'Membre'}
                                    </Text>
                                </TableCell>
                                <TableCell>

                                    <Text type="body2" >
                                        {user.isActive ? (
                                            <Button className={classes.activeIcon} onClick={e => toggleActive(user, false)}>
                                                &#10003;
                                            </Button>
                                        ) : (
                                            user.isActive == null ? (
                                                <Button className={classes.notValidatedIcon} onClick={e => toggleActive(user, true)}>
                                                    &#x26A0;
                                                </Button>
                                            ) : (
                                                <Button className={classes.notActiveIcon} onClick={e => toggleActive(user, true)}>
                                                    x
                                                </Button>
                                            )
                                        )}
                                    </Text>
                                </TableCell>
                                <TableCell align="right">
                                    <Box mr={2} component="span" style={{ cursor: "pointer" }}>
                                        <EditUser groups={groups || []} user={user} mutate={mutateUsers}>
                                            <EditIcon />
                                        </EditUser>
                                    </Box>
                                    <Box component="span" style={{ cursor: "pointer" }}>
                                        <DeleteUser id={user.id} mutate={mutateUsers}>
                                            <DeleteIcon />
                                        </DeleteUser>
                                    </Box>
                                </TableCell>
                            </TableRow>
                        ))}

                        <TableRow>
                            <TableCell colSpan={6} style={{ borderBottom: 'none' }} component="th" scope="row">
                                <Pagination onChange={(e, value) => setPage(value - 1)} className={classes.paginator}
                                    color="primary" count={numberOfPages} shape="rounded" />
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>

            </div>

        </React.Fragment>
    );
};