import { makeStyles } from '@material-ui/core';
import { Box, Popover } from '@material-ui/core';
import React from 'react';
import Text from "./basics/Text";

interface IToGroupTextProps {
    className: string,
    group: {
        name: string,
        users: Array<any>
    },
    sender: any,
    usersAlreadyDeployed: Array<any>,
    onDeploy: any
}

interface IToTextProps {
    conversation: any;
    sender: any,
}


const useStyle = makeStyles(theme => ({
    popover: {
        pointerEvents: 'none',
    },
    toGroup: {
        color: theme.palette.primary.main,
        cursor: 'pointer',
        '&:hover, &:focus, &:active': {
            color: theme.palette.primary.main,
        }
    }
}));


function separateListByCommaForReactList(list: Array<any>) {
    let result = [];
    for (let data of list) {
        result.push(data);
        result.push(<span key={Math.random()}>, </span>)
    }
    // delete last comma
    result.pop();
    return result;
}

export function ToText(props: IToTextProps) {
    const { conversation, sender } = props;
    const classes = useStyle();

    const [usersInConv, setUsersInConv] = React.useState(
        Array.isArray(conversation.users) ? conversation.users
            .filter(u => u.id != sender.id)
            : []
    );

    const [groupInConv, setGroupInConv] = React.useState(
        Array.isArray(conversation.groups) ? conversation.groups : []
    );




    return (
        <>
            {separateListByCommaForReactList([
                ...groupInConv.map(g => (
                    <ToGroupText className={classes.toGroup}
                        key={'g-' + g.id}
                        group={g}
                        sender={sender}
                        onDeploy={group => {
                            setUsersInConv([...usersInConv, ...group.users
                                .filter(user => user.id != sender.id)
                                .filter(user => !usersInConv.find(u => u.id == user.id))
                            ]);
                            setGroupInConv(groupInConv.filter(g => g.id != group.id))
                        }}
                        usersAlreadyDeployed={usersInConv}
        
                    />
                )), 
                ...usersInConv.map(user => <span key={'u-' + user.id}>{user.firstname} {user.lastname}</span>)
            ])}
        </>
    );

}

export function ToGroupText(props: IToGroupTextProps) {
    const classes = useStyle();

    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const deployUsers = () => {
        props.onDeploy(props.group);
    };

    return (
        <span onMouseLeave={handleClose}>
            <span onClick={deployUsers} onMouseLeave={handleClose} className={props.className} onMouseEnter={handleOpen}>
                {props.group.name}
                <Box component="span" fontWeight="bold" ml={.2}>
                    +
                </Box>
            </span>


            <Popover
                className={classes.popover}
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                disableEnforceFocus
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}

            >   <Box padding={1} >
                    {Array.isArray(props.group.users) && props.group.users.map(user => (
                        <Text key={user.id} type="body2">{user.firstname + ' ' + user.lastname}</Text>
                    ))}
                </Box>

            </Popover>
        </span>
    )
}