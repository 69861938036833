import React, { useEffect, useRef, useState } from "react";
import useSWR from "swr";
import axios, { fetcher } from "../providers/axios";
import { Box } from "@material-ui/core";
import Text from "./basics/Text";
import Button from "@material-ui/core/Button";
import ResponseIcon from "./icons/ResponseIcon";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import SendIcon from "./icons/SendIcon";
import Divider from "@material-ui/core/Divider";
import store from "../providers/store";
import { useSnackbar } from "notistack";
import AttachmentIcon from "./icons/AttachmentIcon";
import { API_URL } from "../config";
import Input from "./basics/Input";
import FileAttachmentInput from "./basics/FileAttachmentInput";
import {ToGroupText, ToText} from "./ToGroupText";


const useStyles = makeStyles(theme => ({
    responseButton: {
        border: '1px solid #E8E8E8'
    },
    textGray: {
        color: '#A0A4A8'
    },
    editorClassName: {
        paddingLeft: "20px",
        paddingRight: "20px",
        border: '1px solid #ced4da',
        borderRadius: '4px',
        minHeight: '200px',
        cursor: 'text'
    },
    removeBorder: {
        border: 'none'
    },
    removePaddingLeft: {
        paddingLeft: '0px'
    },
    changeBorderStyle: {
        marginLeft: '0px',
        marginRight: '5px',
        border: 'none',
        '&:hover': {
            border: '1px solid #ced4da'
        }
    },
    attachment: {
        color: '#B0B3B9',
        borderColor: '#B0B3B9',
        fontWeight: 'normal',
        fontSize: 15
    },
    boxResponse: {
        /*
        position: 'absolute',
        bottom: 0,
        left: theme.spacing(1),
        right: theme.spacing(1),
        background: 'white',
        */
    },
    toGroup: {
        color: theme.palette.primary.main,
        cursor: 'pointer',
        '&:hover, &:focus, &:active': {
            color: theme.palette.primary.main,
        } 
    }
}))

export default ({ id, messageId, mutateMessages }) => {
    const classes = useStyles();

    const [openResponse, setOpenResponse] = useState(false);
    const [attachments, setAttachments] = useState(null);


    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const { data: conversation, mutate } = useSWR(`conversation/${id}?lastMessage=${messageId}`, fetcher);
    const { messages, object } = conversation ? conversation : { messages: undefined, object: undefined };

    const editorHtmlRef = useRef();

    useEffect(() => {
        if (Array.isArray(messages) && messages.length) {
            if (store.auth.id != messages[0].senderId) {
                mutateMessages(({ total, results }) => {
                    return {
                        total,
                        results: results.map(message => message.id === messages[0].id ? ({ ...message, read: true }) : message)
                    }
                }, false);
            }

        }

    }, [conversation]);

    async function sendNewMessage() {
        if (editorHtmlRef.current) {
            try {

                const formData = new FormData();
                // @ts-ignore
                formData.append('message', editorHtmlRef.current.editor.editor.innerHTML);
                if (attachments) {
                    for (const attchment of attachments) {
                        formData.append('files', attchment);
                    }

                }

                const { data } = await axios.post(`conversation/${id}/message`, formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    }
                });
                enqueueSnackbar('Message envoyé avec succès !', { variant: 'success' });
                mutate();
                if (Array.isArray(messages) && messages.length) {
                    if (store.auth.id != messages[0].senderId) {
                        mutateMessages(({ total, results }) => {
                            return {
                                total,
                                results: results.map(message => message.id === messages[0].id ? ({ ...message, read: true, responded: true }) : message)
                            }
                        }, false);
                    }
                }

            } catch (err) {
                enqueueSnackbar('Erreur lors de l\'envoi du message', { variant: 'error' });
                console.error(err)
            }
        }
        setOpenResponse(!openResponse);
    }

    async function download(file) {
        const path = API_URL + file.path;

        try {
            const response = await axios.get(path, { responseType: 'arraybuffer' });
            let element = document.createElement('a');
            element.setAttribute('href', 'data:text/plain;base64,' + encodeURIComponent(Buffer.from(response.data, 'binary').toString('base64')));
            element.setAttribute('download', file.name);

            element.style.display = 'none';
            document.body.appendChild(element);

            element.click();

            document.body.removeChild(element);
        } catch (err) {

        }
    }





    return (
        <>
            {openResponse && (
                <Box className={classes.boxResponse}>


                    <Editor
                        ref={editorHtmlRef}
                        editorClassName={classes.editorClassName}
                        toolbarClassName={classes.removeBorder}
                        toolbar={{
                            className: classes.removePaddingLeft,
                            options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker', /*'link', 'image',*/ 'remove', 'history'],

                            inline: {
                                inDropdown: false,
                                component: undefined,
                                dropdownClassName: undefined,
                                options: ['bold', 'italic', 'underline', 'strikethrough'],
                                bold: { className: [classes.changeBorderStyle] },
                                italic: { className: classes.changeBorderStyle },
                                underline: { className: classes.changeBorderStyle },
                                strikethrough: { className: classes.changeBorderStyle },
                            },
                            blockType: { className: classes.removeBorder },
                            fontSize: { className: classes.removeBorder },
                            fontFamily: { className: classes.removeBorder },
                            list: {
                                inDropdown: false,
                                className: classes.removeBorder,
                                component: undefined,
                                dropdownClassName: undefined,
                                options: ['unordered', 'ordered', 'indent', 'outdent'],
                                unordered: { className: classes.changeBorderStyle },
                                ordered: { className: classes.changeBorderStyle },
                                indent: { className: classes.changeBorderStyle },
                                outdent: { className: classes.changeBorderStyle },
                            },
                            textAlign: {
                                inDropdown: false,
                                className: classes.removeBorder,
                                component: undefined,
                                dropdownClassName: undefined,
                                options: ['left', 'center', 'right', 'justify'],
                                left: { className: classes.changeBorderStyle },
                                center: { className: classes.changeBorderStyle },
                                right: { className: classes.changeBorderStyle },
                                justify: { className: classes.changeBorderStyle },
                            },
                            colorPicker: { className: classes.removeBorder },
                            link: {
                                inDropdown: false,
                                className: classes.removeBorder,
                                component: undefined,
                                popupClassName: undefined,
                                dropdownClassName: undefined,
                                showOpenOptionOnHover: true,
                                defaultTargetOption: '_self',
                                options: ['link'],
                                link: { className: classes.changeBorderStyle },
                                unlink: { className: classes.changeBorderStyle },
                                linkCallback: undefined
                            },
                            image: { className: classes.removeBorder },
                            remove: { className: classes.removeBorder },
                            history: { className: classes.removeBorder }

                        }}
                    />
                    <Box mt={1}>
                        {/*
                        <Input fullWidth  type="file" onChange={e => setAttachments(e.target['files'])} inputProps={{multiple: true}} />   
                        */}
                        <FileAttachmentInput onChange={e => setAttachments(e.target['files'])} />
                    </Box>
                    <Box display="flex" justifyContent="flex-end" mt={1} mb={1}>
                        <Button onClick={e => setOpenResponse(false)}>

                            <Text type="small1">
                                ANNULER
                            </Text>
                        </Button>
                        <Button variant="contained" color="primary" onClick={sendNewMessage}>
                            <Box component="span" mr={.5}>
                                <SendIcon />
                            </Box>
                            <Text color="white" type="small1">
                                ENVOYER
                            </Text>
                        </Button>
                    </Box>

                    <Divider />
                </Box>
            )}

            {(messages || []).map((message, index) => (
                <div key={message.id}>
                    {index != 0 && (
                        <Divider />

                    )}
                    <Box pb={1} pt={1}>

                        <Box display="flex" justifyContent="space-between" alignItems="center">
                            <Text type="subtitle2" color="primary">
                                {object}
                            </Text>
                            {!openResponse && message.senderId !== store.auth.id && (
                                <Button className={classes.responseButton}
                                    onClick={e => setOpenResponse(!openResponse)}>
                                    <Box component="span" mr={.5}>
                                        <ResponseIcon />
                                    </Box>
                                    <Text type="body2">
                                        Répondre
                                    </Text>
                                </Button>
                            )}

                        </Box>
                        <Box style={{ width: 'fit-content' }}>
                            {/*
                            <Box display="flex">
                                <Box textAlign="right">
                                    <Text type="body2">
                                        <span className={classes.textGray}> De : </span>
                                    </Text>
                                    <Text type="body2">
                                        <span className={classes.textGray}> À : </span>
                                    </Text>
                                </Box>
                                <Box ml={1}>
                                    {message.sender && (
                                        <Text type="body2">
                                            {message.sender.firstname} {message.sender.lastname}
                                        </Text>
                                    )}

                                    {(Array.isArray(conversation.groups) || Array.isArray(conversation.users)) && (
                                        <Text type="body2">
                                            {getTo(message.sender)}
                                        </Text>
                                    )}
                                </Box>
                            </Box>
                            */}
                            {message.sender && (
                                <Text type="body2">
                                    <span className={classes.textGray}> De :</span> {message.sender.firstname} {message.sender.lastname}
                                </Text>
                            )}

                            {(Array.isArray(conversation.groups) || Array.isArray(conversation.users)) && (
                                <Text type="body2">
                                    <span className={classes.textGray}> À : </span>
                                    <ToText sender={message.sender} conversation={conversation}  />
                                </Text>
                            )}
                        </Box>
                        {message.files && (
                            <Box mt={.5}>
                                {message.files.map(file => (
                                    <Box mr={.5} component="span">
                                        <Button variant="outlined" className={classes.attachment} onClick={e => download(file)}>
                                            {file.name}
                                            <Box component="span" ml={1}>
                                                <AttachmentIcon />
                                            </Box>

                                        </Button>
                                    </Box>
                                ))}
                            </Box>
                        )}
                        <Box dangerouslySetInnerHTML={{ __html: message.message }} />
                    </Box>
                </div>
            ))}



        </>
    );
};