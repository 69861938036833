export default {
    h1: {
        fontSize: 56,
        letterSpacing: '0.4px',
        fontWeight: 600,
        lineHeight: '60px'
    },
    h2: {
        fontSize: 40,
        letterSpacing: '0.2px',
        fontWeight: 600,
        lineHeight: '46px',
        color: '#25282B!important'
    },
    h3: {
        fontSize: 28,
        letterSpacing: '0.2px',
        fontWeight: 600,
        lineHeight: '34px',
        color: '#25282B!important'
    },
    h4: {
        fontSize: 26,
        letterSpacing: '0.2px',
        fontWeight: 600,
        lineHeight: '34px'
    },
    h5: {
        fontSize: 20,
        letterSpacing: '0.2px',
        fontWeight: 600,
        lineHeight: '26px'
    },
    h6: {
        fontSize: 18,
        letterSpacing: '0.1px',
        fontWeight: 400,
        lineHeight: '24px'
    },
    subtitle1: {
        fontSize: 16,
        letterSpacing: '0.1px',
        fontWeight: 600,
        lineHeight: '24px'
    },
    subtitle2: {
        fontSize: 14,
        letterSpacing: '0.1px',
        fontWeight: 600,
        lineHeight: '20px'
    },
    body1: {
        fontSize: 16,
        letterSpacing: '0.1px',
        fontWeight: 400,
        lineHeight: '24px'
    },
    body2: {
        fontSize: 14,
        letterSpacing: '0.1px',
        fontWeight: 400,
        lineHeight: '20px'
    },
    button: {
        fontSize: 15,
        letterSpacing: '0.1px',
        fontWeight: 550,
        lineHeight: '18px'
    },
    small1: {
        fontSize: 13,
        letterSpacing: '0.1px',
        fontWeight: 400,
        lineHeight: '16px'
    },
    small2: {
        fontSize: 12,
        letterSpacing: '0.1px',
        fontWeight: 600,
        lineHeight: '16px'
    },
    small3: {
        fontSize: 12,
        letterSpacing: '0.2px',
        fontWeight: 600,
        lineHeight: '12px'
    }
}