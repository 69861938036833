import React, {useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';


export default (props) => {
    let history = useHistory();

    useEffect(() => {
       
    }, []);


    return <React.Fragment>

    </React.Fragment>;
};