import React from 'react';
import Router from './router/router';
import { observer } from "mobx-react-lite";
import store from "./providers/store";
import Menu from "./components/Menu/Menu";
import { ThemeProvider } from '@material-ui/core/styles';
import index from "./theme";
import Login from "./pages/Login";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { SnackbarProvider } from 'notistack';
import { useHistory, useParams } from 'react-router-dom';

const useStyle = makeStyles(theme => ({
    main: {
        padding: '0 50px 0 50px',
        [theme.breakpoints.down('xs')]: {
            padding: 0,
        }
    }
}))

function App() {
    const classes = useStyle();
    const history = useHistory();

    return (
        <SnackbarProvider>
            <ThemeProvider theme={index}>

            
                {typeof history?.location?.pathname === 'string' && history?.location?.pathname.includes('/change-password')  ? (
                    <main className={classes.main}>
                        <Router />
                    </main>
                ) : (
                     store.isAuth ? (
                        <React.Fragment>
                            <Menu />
                            <main className={classes.main}>
                                <Router />
                            </main>
                        </React.Fragment>
                    ) : <Login />
                )}
            
            </ThemeProvider>
        </SnackbarProvider>

    );
}

export default observer(App);
