import React from "react";
import {Route, Switch} from "react-router-dom";
import Dashboard from "../pages/Dashboard";
import Files from '../pages/folders/Files';
import Users from "../pages/Users";
import AdminUsers from "../pages/admin/AdminUsers";
import AdminGroups from "../pages/admin/AdminGroups";
import Received from "../pages/messages/Received";
import Send from "../pages/messages/Send";
import Profile from "../pages/Profile";
import ChangePassword from "../pages/ChangePassword";


export default props => {
    return (
        <Switch>
            <Route path="/" exact render={props => <Dashboard {...props} />} />
            <Route path="/users" exact render={props => <Users {...props} />} />

            <Route path="/change-password/:securekey" exact render={props => <ChangePassword {...props} />} />


            <Route path="/files" exact render={props => <Files {...props} />} />
            <Route path="/files/:id" exact render={props => <Files {...props} />} />


            <Route path="/admin/users" exact render={props => <AdminUsers {...props} />} />
            <Route path="/admin/groups" exact render={props => <AdminGroups {...props} />} />

            <Route path="/messages/received" exact render={props => <Received {...props} />} />
            <Route path="/conversations/:conversationId/messages/:messageId" exact render={props => <Received {...props} />} />
            <Route path="/messages/send" exact render={props => <Send {...props} />} />

            <Route path="/profile" exact render={props => <Profile {...props} />} />

            



        </Switch>
    )
};