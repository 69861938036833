import { makeStyles } from "@material-ui/core";

export default makeStyles(theme => ({
    card: {
        marginBottom: theme.spacing(2),
        background: '#FFFFFF',
        boxShadow: '0px 12px 26px rgba(16, 30, 115, 0.06)',
        borderRadius: '8px',
        minHeight: '30vh',
        overflowY: 'auto',
        [theme.breakpoints.down('xs')]: {
            minHeight: 0,
            boxShadow: 'none'
        },
    },
    pageHead: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        [theme.breakpoints.down('xs')]: {
            margin: theme.spacing(1),
            /*
            justifyContent: 'center',
            '& > h2': {
                textAlign: 'center'
            }
            */
        }
    },
    cardHead: {
        margin: theme.spacing(1),
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
}));