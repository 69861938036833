import { Avatar, Box, Button, FormControl, makeStyles } from '@material-ui/core';
import React, { useState } from 'react';
import Input from '../components/basics/Input';
import Text from '../components/basics/Text';
import axios from '../providers/axios';
import { useSnackbar } from "notistack";

const useStyles = makeStyles(theme => ({
    subtitle: {
        color: '#1F2836!important'
    },
    checkIcon: {
        backgroundColor: theme.palette.success.main
    },
    returnBtn: {
        color: '#BDBDBD'
    }
}));

export default ({ setDisplayCreateAccount }) => {
    const classes = useStyles();
    const [displayAccoundCreated, setDisplayAccountCreated] = useState(false);
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();


    async function register(event) {
        event.preventDefault();
        const formData = new FormData(event.target);
        let data = {
            firstname: '',
            lastname: '',
            email: '',
            password: '',
            company: '',
            phone: ''
        };
        for (const property in data) {
            data[property] = formData.get(property);
        }
        try {
            await axios.post('register', data);
            enqueueSnackbar(`Demande d'inscription envoyé !`, { variant: 'success' });
            setDisplayAccountCreated(true);
        } catch (err) {
            enqueueSnackbar(`Une erreur est survenue`, { variant: 'error' });
        }

    }

    return (
        !displayAccoundCreated ? (
            <>

                <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Text type="h3">
                        Créer votre compte
                    </Text>
                    <Box component="span" style={{cursor: 'pointer'}}  onClick={e => setDisplayCreateAccount(false)}>
                        <Text type="small1" className={classes.returnBtn}>
                            {"<"} Retour
                        </Text>
                    </Box>
                </Box>
                <Box mt={.5}>
                    <Text type="small1" className={classes.subtitle}>
                        Informations personnelles
                    </Text>
                </Box>
                <form onSubmit={register}>
                    <Box mb={2} mt={1}>

                        <FormControl fullWidth>
                            <Box mt={2} mb={.3}>
                                <label htmlFor="email">
                                    <Text type="subtitle2">
                                        Adresse email
                                </Text>
                                </label>
                            </Box>
                            <Input type="email" required fullWidth id="email" name="email" />
                        </FormControl>
                        <FormControl fullWidth>
                            <Box mt={2} mb={.3}>
                                <label htmlFor="password">
                                    <Text type="subtitle2">
                                        Mot de passe
                                </Text>
                                </label>
                            </Box>

                            <Input type="password" required fullWidth id="password" name="password" />
                        </FormControl>

                        <Box display="flex" alignItems="center" justifyContent="space-between" >
                            <Box width="49%">
                                <FormControl fullWidth>
                                    <Box mt={2} mb={.3}>
                                        <label htmlFor="firstname">
                                            <Text type="subtitle2">
                                                Prénom
                                        </Text>
                                        </label>
                                    </Box>

                                    <Input required fullWidth id="firstname" name="firstname" />
                                </FormControl>
                            </Box>
                            <Box width="49%">
                                <FormControl fullWidth>
                                    <Box mt={2} mb={.3}>
                                        <label htmlFor="lastname">
                                            <Text type="subtitle2">
                                                Nom de famille
                                        </Text>
                                        </label>
                                    </Box>

                                    <Input required fullWidth id="lastname" name="lastname" />
                                </FormControl>
                            </Box>
                        </Box>
                        <Box display="flex" alignItems="center" justifyContent="space-between" >
                            <Box width="49%">
                                <FormControl fullWidth>
                                    <Box mt={2} mb={.3}>
                                        <label htmlFor="company">
                                            <Text type="subtitle2">
                                                Entreprise
                                    </Text>
                                        </label>
                                    </Box>

                                    <Input required fullWidth id="company" name="company" />
                                </FormControl>
                            </Box>
                            <Box width="49%">
                                <FormControl fullWidth>
                                    <Box mt={2} mb={.3}>
                                        <label htmlFor="phone">
                                            <Text type="body2">
                                                Téléphone
                                                    </Text>
                                        </label>
                                    </Box>
                                    <Input fullWidth type="text" id="phone" name="phone" />
                                </FormControl>
                            </Box>
                        </Box>



                    </Box>


                    <Button type="submit" variant="contained" color="primary" fullWidth>
                        <Text type="small1" color="white">
                            J'envoie ma demande d'inscription
                        </Text>
                    </Button>

                </form>


            </>
        ) : (
            <>
                <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Text type="h3">
                        Créer votre compte
                    </Text>
                    <Box component="span" style={{cursor: 'pointer'}}  onClick={e => setDisplayCreateAccount(false)}>
                        <Text type="small1"  className={classes.returnBtn}>
                            {"<"} Retour
                        </Text>
                    </Box>
                </Box>

                <Box mt={8} mb={8} display="flex" flexDirection="column" justifyContent="center" alignItems="center" >
                    <Avatar className={classes.checkIcon}>
                        &#10003;
                    </Avatar>
                    <Box mt={1} mb={1}>
                        <Text type="subtitle1">
                            Inscription terminée !
                        </Text>
                    </Box>
                    <Box mt={1} mb={1} mr={7} ml={7}>
                        <Text type="body2">
                            Une fois votre compte validé par l'administrateur,
                            vous pourrez vous connecter à l'application avec les identifiants saisient à l'étape précédente.
                        </Text>
                    </Box>
                </Box>

                <Button type="submit" variant="contained" color="primary" fullWidth onClick={e => setDisplayCreateAccount(false)}>
                    <Text type="small1" color="white">
                        VALIDER
                    </Text>
                </Button>

            </>
        )
    );
};