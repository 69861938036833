import React, { useEffect, useRef, useState } from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Dialog from "@material-ui/core/Dialog";
import Box from "@material-ui/core/Box";
import Text from "./basics/Text";
import Button from "@material-ui/core/Button";
import { fade, Grid, Switch, TextField } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import Input from "./basics/Input";
import Select from "./basics/Select";
import axios from '../providers/axios';
import Autocomplete from "@material-ui/lab/Autocomplete";
import CustomAutocomplete from '../components/basics/CustomAutocomplete';
import { useSnackbar } from 'notistack';

interface IEditUserProps {
    children: any,
    user?: any,
    mutate: Function,
    groups: Array<any>
}

const useStyle = makeStyles(theme => ({
    row: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'baseline'
    },
    rowButtons: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center'
    },
    dialog: {
        padding: '15px'
    },
    content: {
        padding: theme.spacing(2),
        minWidth: '25vw'
    },
    closeBox: {
        color: '#9FA2B4',
        fontWeight: 400,
        fontSize: '24px',
        letterSpacing: '0.2px'
    },
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    chip: {
        margin: 2,
    },
}));

export default (props: IEditUserProps) => {
    const classes = useStyle();
    const [open, setOpen] = useState(false);
    const formRef = useRef(null);
    const [isActive, setIsActice] = useState(props.user && props.user.isActive);
    const [userInGroups, setUserInGroups] = useState([]);
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();


    function toggleOpen() {
        setOpen(!open);
    }

    async function edit(event) {
        event.preventDefault();
        const isAddAction = !(props.user && props.user.id);

        const formData = new FormData(event.target);

        let data = {};
        for (const property of ['email', 'role', 'firstname', 'lastname', 'company', 'phone', 'password']) {
            data[property] = formData.get(property);
        }


        if (
            data['phone'] && (
                typeof data['phone'] !== 'string' ||
                data['phone'].length > 15 ||
                data['phone'].match(new RegExp('[a-zA-Z]')
                )
            )
        ) {
            enqueueSnackbar('Numéro de téléphone incorrect', { variant: 'error' });
        } else {
            data['groups'] = userInGroups.map(({ id }) => ({ id }));
            data['isActive'] = isActive;
            try {
                if (isAddAction) {
                    const user = (await axios.post('users', data)).data;
                } else {
                    const user = (await axios.put(`users/${props.user.id}`, data)).data;
                }
                toggleOpen();
                enqueueSnackbar('Utilisateur enregistré avec succès !', { variant: 'success' });
                props.mutate();
            } catch (err) {
                enqueueSnackbar('Erreur lors de l\'enregistrement de l\'utilisateur', { variant: 'error' });
            }
        }
    }

    useEffect(() => {
        let interval = setInterval(() => {
            if ((formRef && formRef.current) || !props.user) {
                if (props.user) {
                    for (const property in props.user) {
                        if (formRef.current[property]) {
                            formRef.current[property].value = props.user[property];
                        }
                    }
                }
                clearInterval(interval);
            }
        }, 50);

        return () => {
            clearInterval(interval)
        };
    }, [open]);

    useEffect(() => {
        if (props.user) {
            if (Array.isArray(props.user.groups)) {
                setUserInGroups(props.user.groups);
            }
            setIsActice(props.user.isActive);
        }

    }, [props.user]);

    function updateGroups(event) {
        const id = event.target.value[(event.target.value as Array<any>).length - 1];

        if (userInGroups.some(g => g.id == id)) {
            setUserInGroups(userInGroups.filter(g => g.id != id));
        } else {
            setUserInGroups([...userInGroups, props.groups.find(g => g.id == id)]);
        }
    }

    return (
        <>
            <span onClick={toggleOpen}>
                {props.children}
            </span>
            <Dialog onClose={toggleOpen} className={classes.dialog} open={open}>
                <div className={classes.content}>
                    <Box mb={.1} className={classes.rowButtons}>
                        <Box component="span" style={{ cursor: 'pointer' }} onClick={toggleOpen}>
                            <span className={classes.closeBox}>X</span>
                        </Box>
                    </Box>
                    {props.user && props.user.id ? (
                        <Text type="h3">
                            Modifier {props.user.firstname} {props.user.lastname}
                        </Text>
                    ) : (
                        <Text type="h3">
                            Nouvel utilisateur
                        </Text>
                    )}
                    <form onSubmit={edit} ref={formRef}>
                        <Box mb={1} mt={1}>
                            <Grid container>

                                <Grid item xs={12} sm={6}>
                                    <Box mr={2}>
                                        <FormControl fullWidth>
                                            <Box mt={2} mb={.3}>
                                                <label htmlFor="lastname">
                                                    <Text type="body2">
                                                        Nom
                                                    </Text>
                                                </label>
                                            </Box>
                                            <Input required fullWidth id="lastname" name="lastname" />
                                        </FormControl>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Box mr={2}>
                                        <FormControl fullWidth>
                                            <Box mt={2} mb={.3}>
                                                <label htmlFor="firstname">
                                                    <Text type="body2">
                                                        Prenom
                                                    </Text>
                                                </label>
                                            </Box>
                                            <Input required fullWidth id="firstname" name="firstname" />
                                        </FormControl>
                                    </Box>
                                </Grid>
                                <Grid item xs={12}>
                                    <Box mr={2}>
                                        <FormControl fullWidth>
                                            <Box mt={2} mb={.3}>
                                                <label htmlFor="company">
                                                    <Text type="body2">
                                                        Entreprise
                                                    </Text>
                                                </label>
                                            </Box>
                                            <Input required fullWidth id="company" name="company" />
                                        </FormControl>
                                    </Box>
                                </Grid>
                                <Grid item xs={12}>
                                    <Box mr={2}>
                                        {/*
                                        <FormControl fullWidth>
                                            <Box mt={2} mb={.3}>
                                                <label htmlFor="groups">
                                                    <Text type="body2">
                                                        Groupes
                                                    </Text>
                                                </label>
                                            </Box>
                                            <MuiSelect
                                                multiple
                                                onChange={updateGroups}
                                                value={userInGroups}
                                                input={<Input />}
                                                       renderValue={(selected) => (
                                                           <div className={classes.chips}>
                                                               {(selected as any[]).map((group) => (
                                                                   <Chip key={group.id} label={group.name} className={classes.chip} />
                                                               ))}
                                                           </div>
                                                       )}
                                                       MenuProps={MenuProps}
                                            >
                                                {props.groups.map((group) => (
                                                    <MenuItem key={group.id} value={group.id}>
                                                        {group.name}
                                                    </MenuItem>
                                                ))}
                                            </MuiSelect>
                                        </FormControl>
                                        */}
                                        {/*
                                        <FormControl fullWidth>
                                            <Box mt={2} mb={.3}>
                                                <label htmlFor="groups">
                                                    <Text type="body2">
                                                        Groupes
                                                    </Text>
                                                </label>
                                            </Box>
                                            <GroupsSelector value={userInGroups} onChange={value => setUserInGroups(value)} />

                                        </FormControl>
                                        */}
                                        <FormControl fullWidth>
                                            <Box mt={2} mb={.3}>
                                                <label htmlFor="groups">
                                                    <Text type="body2">
                                                        Groupes
                                                    </Text>
                                                </label>
                                            </Box>
                                            {/*
                                            <Autocomplete
                                                options={props.groups}
                                                getOptionLabel={group => group['name']}
                                                multiple
                                                value={userInGroups}
                                                onChange={(e, newValue) => setUserInGroups(newValue)}
                                                renderInput={(params) => (
                                                    <>
                                                        <TextField
                                                            {...params}
                                                            variant="outlined"

                                                        />
                                                    </>
                                                )}
                                            />
                                            */}
                                            <CustomAutocomplete AutoCompleteProps={{
                                                options: props.groups,
                                                multiple: true,
                                                value: userInGroups,
                                                onChange: (e, newValue) => setUserInGroups(newValue)
                                            }} getOptionLabel={group => group['name']} />

                                        </FormControl>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Box mr={2}>
                                        <FormControl fullWidth>
                                            <Box mt={2} mb={.3}>
                                                <label htmlFor="role">
                                                    <Text type="body2">
                                                        Role
                                                    </Text>
                                                </label>
                                            </Box>
                                            <Select name="role" id="role">
                                                <option value="user">Membre</option>
                                                <option value="admin">Administrateur</option>
                                            </Select>

                                        </FormControl>
                                    </Box>
                                </Grid>

                                <Grid item xs={12} sm={6}>
                                    <Box mr={2}>
                                        <FormControl fullWidth>
                                            <Box mt={2} mb={.3}>
                                                <label htmlFor="phone">
                                                    <Text type="body2">
                                                        Téléphone
                                                    </Text>
                                                </label>
                                            </Box>
                                            <Input fullWidth type="text" id="phone" name="phone" />
                                        </FormControl>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Box mr={2}>
                                        <FormControl fullWidth>
                                            <Box mt={2} mb={.3}>
                                                <label htmlFor="email">
                                                    <Text type="body2">
                                                        Email
                                                    </Text>
                                                </label>
                                            </Box>
                                            <Input required fullWidth type="email" id="email" name="email" />
                                        </FormControl>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Box mr={2}>
                                        <FormControl fullWidth>
                                            <Box mt={2} mb={.3}>
                                                <label htmlFor="password">
                                                    <Text type="body2">
                                                        Mot de passe
                                                    </Text>
                                                </label>
                                            </Box>
                                            <Input fullWidth type="password" id="password" name="password" />
                                        </FormControl>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Box mr={2}>
                                        <FormControl fullWidth>
                                            <Box mt={2} mb={.3}>
                                                <label htmlFor="isActive">
                                                    <Text type="body2">
                                                        Actif
                                                    </Text>
                                                </label>
                                            </Box>
                                            <Switch color="primary" checked={isActive} onChange={(e) => { setIsActice(!isActive) }} />
                                        </FormControl>
                                    </Box>
                                </Grid>
                            </Grid>


                        </Box>
                        <Box mb={1} mt={2} className={classes.row}>
                            <Box mr={1}>
                                <Button variant="contained" color="primary" type="submit">
                                    <Text type="small1" color="white">
                                        ENREGISTRER
                                    </Text>
                                </Button>
                            </Box>
                            <Box>
                                <Button onClick={toggleOpen} type="button">
                                    <Text type="small1">
                                        ANNULER
                                    </Text>
                                </Button>
                            </Box>

                        </Box>
                    </form>
                </div>
            </Dialog>
        </>
    );
};