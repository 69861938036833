import Dialog from "@material-ui/core/Dialog";
import Box from "@material-ui/core/Box";
import ErrorIcon from "./icons/ErrorIcon";
import Text from "./basics/Text";
import Button from "@material-ui/core/Button";
import React, {useState} from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import axios from '../providers/axios';
import {useSnackbar} from "notistack";

interface IDeleteFileProps {
    id: number | string | Array<number | string>,
    mutate: Function,
    toggleOpen: any,
    open: boolean,
    setFilesSelected?: Function
}

const useStyle = makeStyles(theme => ({
    row: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'baseline'
    },
    rowButtons: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center'
    },
    dialog: {
        padding: '15px'
    },
    content: {
        padding: theme.spacing(2),
        minWidth: '25vw'
    },
    closeBox: {
        color: '#9FA2B4',
        fontWeight: 400,
        fontSize: '24px',
        letterSpacing: '0.2px'
    },
    button: {
        paddingTop: '15px',
        paddingBottom: '15px',
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-start'
    }
}));

export default (props: IDeleteFileProps) => {
    const classes = useStyle();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const {open, toggleOpen, mutate, id} = props;

    async function confirmDelete() {
        try {
            if (Array.isArray(id)) {
                await axios.delete(`files/${id.join(',')}`);
                if (typeof props.setFilesSelected === 'function') {
                    props.setFilesSelected([]);
                }
                enqueueSnackbar('Fichier(s) supprimé avec succès !', {variant: 'success'});
                mutate();
            } else {
                await axios.delete(`files/${id}`);
                enqueueSnackbar('Fichier(s) supprimé avec succès !', {variant: 'success'});
                mutate();
            }

        } catch (err) {
            console.log(err);
            enqueueSnackbar('Erreur lors de l\'a suppression du/des fichier(s)', {variant: 'error'});

        }
        toggleOpen();
    }

    return (
        <Dialog onClose={toggleOpen} className={classes.dialog} open={open}>
            <div className={classes.content}>
                <Box mb={1} className={classes.rowButtons}>
                    <Box component="span" style={{cursor: 'pointer'}} onClick={toggleOpen}>
                        <span className={classes.closeBox}>X</span>
                    </Box>
                </Box>
                <Box mb={1} mt={1} className={classes.row}>
                    <ErrorIcon/>
                    <Box ml={1}>
                        <Text type="h6">
                            Attention
                        </Text>
                    </Box>
                </Box>
                <Box mb={1} mt={1} className={classes.row}>

                    <Text type="body2">
                        La suppression est définitive.
                    </Text>

                </Box>
                <Box mb={1} mt={2} className={classes.rowButtons}>
                    <Box mr={1}>
                        <Button onClick={toggleOpen}>
                            <Text type="small1">
                                ANNULER
                            </Text>
                        </Button>
                    </Box>
                    <Button variant="contained" color="primary" onClick={confirmDelete}>
                        <Text type="small1" color="white">
                            Ok
                        </Text>
                    </Button>
                </Box>


            </div>


        </Dialog>

    );
};

