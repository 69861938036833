import Dialog from "@material-ui/core/Dialog";
import Box from "@material-ui/core/Box";
import ErrorIcon from "./icons/ErrorIcon";
import Text from "./basics/Text";
import Button from "@material-ui/core/Button";
import React, {useState} from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import axios from '../providers/axios';
import { mutate } from "swr";
import {useSnackbar} from "notistack";

interface IDeleteUserProps {
    children: any,
    id: number | string,
    mutate: Function
}

const useStyle = makeStyles(theme => ({
    row: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'baseline'
    },
    rowButtons: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center'
    },
    dialog: {
        padding: '15px'
    },
    content: {
        padding: theme.spacing(2),
        minWidth: '25vw'
    },
    closeBox: {
        color: '#9FA2B4',
        fontWeight: 400,
        fontSize: '24px',
        letterSpacing: '0.2px'
    }
}));

export default (props: IDeleteUserProps) => {
    const classes = useStyle();
    const [open, setOpen] = useState(false);
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    function toggleOpen() {
        setOpen(!open);
    }

    async function confirmDelete() {
        try {
            await axios.delete(`users/${props.id}`);
            props.mutate();
            enqueueSnackbar('Utilisateur supprimé avec succès !', {variant: 'success'});
        } catch (err) {
            enqueueSnackbar('Erreur lors de la suppression de l\'utilisateur', {variant: 'error'});
        }
        toggleOpen();
    }

    return (
        <>
            <span onClick={toggleOpen}>
                {props.children}
            </span>

            <Dialog onClose={toggleOpen} className={classes.dialog} aria-labelledby="simple-dialog-title" open={open}>
                <div className={classes.content}>
                    <Box mb={1} className={classes.rowButtons}>
                        <Box component="span" style={{cursor: 'pointer'}} onClick={toggleOpen}>
                            <span className={classes.closeBox}>X</span>
                        </Box>
                    </Box>
                    <Box mb={1} mt={1} className={classes.row}>
                        <ErrorIcon/>
                        <Box ml={1}>
                            <Text type="h6">
                                Attention
                            </Text>
                        </Box>
                    </Box>
                    <Box mb={1} mt={1} className={classes.row}>

                        <Text type="body2">
                            La suppression est définitive.
                        </Text>

                    </Box>
                    <Box mb={1} mt={2} className={classes.rowButtons}>
                        <Box mr={1}>
                            <Button onClick={toggleOpen}>
                                <Text type="small1">
                                    ANNULER
                                </Text>
                            </Button>
                        </Box>
                        <Button variant="contained" color="primary" onClick={confirmDelete}>
                            <Text type="small1" color="white">
                                OK
                            </Text>
                        </Button>
                    </Box>


                </div>


            </Dialog>
        </>
    );
};

