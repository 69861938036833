import React, { useState } from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { Checkbox, Popper } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import FolderIcon from "./icons/FolderIcon";
import Text from "./basics/Text";
import FileIcon from "./icons/FileIcon";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import MenuList from "@material-ui/core/MenuList";
import MenuItem from "@material-ui/core/MenuItem";
import TrashIcon from "./icons/TrashIcon";
import RenameIcon from "./icons/RenameIcon";
import { useHistory } from 'react-router-dom';
import store from "../providers/store";
import axios from '../providers/axios';
import EditFolderModal from "./EditFolderModal";
import EditFileModal from "./EditFileModal";
import DeleteFileModal from "./DeleteFileModal";
import DownloadIcon from "./icons/DownloadIcon";
import { API_URL } from "../config";
import MoveIcon from "./icons/MoveIcon";
import BroadcastIcon from "./icons/BroadcastIcon";
import LinkIcon from "./icons/LinkIcon";
import MoveFileMenu from "./MoveFileMenu";
import { useSnackbar } from "notistack";
import { mutate } from "swr";
import ClipboardJS from 'clipboard';

const useStyles = makeStyles(theme => ({
    icon: {
        width: '100px',
        height: '100px'
    },
    boxFile: {
        display: "flex",
        alignItems: "flex-start",
        cursor: 'pointer',
        borderRadius: '5px',
        '&:hover': {
            backgroundColor: '#EBF0FF',
            '& .MuiCheckbox-root': {
                opacity: 1
            }
        }

    },
    checkboxFile: {
        opacity: 0,
        [theme.breakpoints.down('sm')]: {
            opacity: 1
        },
        '&:hover': {
            opacity: 1
        }
    },
    menuItem: {
        padding: 0,
        width: '100%'
    },
    menuItemButton: {
        paddingTop: '15px',
        paddingBottom: '15px',
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-start'
    },
    textMenu: {
        marginLeft: theme.spacing(1)
    }
}));


interface IFileProps {
    filesSelected: Array<any>,
    file: any,
    selectOrUnselect: Function,
    mutate: Function,
}


export default (props: IFileProps) => {
    const classes = useStyles();

    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef<HTMLButtonElement>(null);
    const buttonCopyRef = React.useRef<HTMLButtonElement>(null);
    const { filesSelected, selectOrUnselect, file } = props;

    const [openEdit, setOpenEdit] = useState(false);
    const [openDelete, setOpenDelete] = useState(false);

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();


    function toggleEdit() {
        setOpenEdit(!openEdit);
    }

    function toggleDelete() {
        setOpenDelete(!openDelete);
    }

    const history = useHistory();

    const handleToggle = (e = null) => {
        e && e.preventDefault();
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event: React.MouseEvent<EventTarget>) => {
        if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
            return;
        }

        setOpen(false);
    };

    function handleListKeyDown(event: React.KeyboardEvent) {
        if (event.key === 'Tab') {
            event.preventDefault();
            setOpen(false);
        }
    }

    // return focus to the button when we transitioned from !open -> open
    const prevOpen = React.useRef(open);
    React.useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current!.focus();
        }

        prevOpen.current = open;
    }, [open]);


    React.useEffect(() => {
        const link = API_URL + file.path;

        let clipboard = new ClipboardJS(`.clipboard_${file.id}`, {
            text: function (trigger) {
                console.log(file.path)
                return link;
            }
        });



        clipboard.on('success', function (e) {
            enqueueSnackbar(`Lien "${link}" copié !`, { variant: 'info' })
        });


        clipboard.on('error', function (e) {

        });

        return () => {
            clipboard.destroy();
        };
    }, []);

    const isFolder = !file.path;

    function goTo(e, id) {
        e && e.stopPropagation && e.stopPropagation();
        const isLeftClick = e.button == 0;
        if (isLeftClick && isFolder) {
            store.goToLink(`/files/${id}`);
            //history.push(`/files/${id}`);
        } else {
            handleToggle()
        }
    }

    async function download(file) {
        const path = API_URL + file.path;

        try {
            const response = await axios.get(path, { responseType: 'arraybuffer' });
            let element = document.createElement('a');
            element.setAttribute('href', 'data:text/plain;base64,' + encodeURIComponent(Buffer.from(response.data, 'binary').toString('base64')));
            element.setAttribute('download', file.name);

            element.style.display = 'none';
            document.body.appendChild(element);

            element.click();

            document.body.removeChild(element);
        } catch (err) {

        }
    }

    async function copyLink() {
        if (buttonCopyRef && buttonCopyRef.current) {
            buttonCopyRef.current.click();
        }
    }

    async function moveFile(newFolder) {
        try {
            await axios.put(`files/${file.id}`, { parentId: newFolder.id });
            enqueueSnackbar('Fichier déplacé avec succès !', { variant: 'success' });
            mutate(`files/${file.parentId || ''}`, data => data.filter(d => d.id != file.id))
        } catch (err) {
            enqueueSnackbar('Erreur lors du déplacement du fichier', { variant: 'error' });
        }
    }


    return (
        <>
            <button className={`clipboard_${file.id}`} style={{ display: 'none' }} ref={buttonCopyRef} />
            {isFolder ? (
                <EditFolderModal folder={file} mutate={props.mutate} open={openEdit} toggleOpen={toggleEdit} />
            ) : <EditFileModal file={file} mutate={props.mutate} open={openEdit} toggleOpen={toggleEdit} />}
            <DeleteFileModal toggleOpen={toggleDelete} open={openDelete} mutate={props.mutate} id={props.file.id} />


            <span ref={anchorRef} onClick={e => goTo(e, file.id)} onContextMenu={handleToggle}>
                <Box className={classes.boxFile} key={file.id}>
                    {filesSelected.some(f => f.id == file.id) ? (
                        <Checkbox style={{ padding: 0 }} onClick={e => e.stopPropagation()} checked onChange={e => selectOrUnselect(file, e)} />
                    ) : (
                        <Checkbox style={{ padding: 0 }} onClick={e => e.stopPropagation()} checked={false}
                            onChange={e => selectOrUnselect(file, e)}
                            className={classes.checkboxFile} />
                    )}
                    <Box component="span">

                        <Box display="flex" justifyContent="center" alignItems="center" mb={1}>
                            {isFolder ? <FolderIcon className={classes.icon} /> : <FileIcon className={classes.icon} />}

                        </Box>

                        <Box display="flex" justifyContent="center" alignItems="center">
                            <Text type="small1">
                                {file.name}

                            </Text>
                        </Box>
                    </Box>
                    <Checkbox style={{ opacity: 0, padding: 0 }} />
                    <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal
                        style={{ zIndex: 1000 }} placement="right-start" onClick={e => e.stopPropagation()}>
                        {({ TransitionProps, placement }) => (
                            <Grow {...TransitionProps}>
                                <Paper>
                                    <ClickAwayListener onClickAway={handleClose}>
                                        <MenuList autoFocusItem={open} onKeyDown={handleListKeyDown}>
                                            {(!isFolder && file.canDownload) && (
                                                <MenuItem key="download" onClick={e => download(file)}>
                                                    <DownloadIcon />
                                                    <Text type="body2" className={classes.textMenu}>
                                                        Télécharger
                                                    </Text>

                                                </MenuItem>
                                            )}
                                            {!isFolder && (
                                                <MenuItem key="copy" onClick={copyLink} className="clipboard">
                                                    <LinkIcon />
                                                    <Text type="body2" className={classes.textMenu}>
                                                        Copier le lien
                                                 </Text>

                                                </MenuItem>
                                            )}
                                            {(!isFolder && file.canWrite) && (
                                                 <MoveFileMenu moveFile={moveFile} />
                                            )}
                                        
                                            {file.canWrite && (
                                                <MenuItem key="rename" onClick={toggleEdit}>
                                                    <RenameIcon />
                                                    <Text type="body2" className={classes.textMenu}>
                                                        {isFolder ? 'Modifier' : 'Renommer'}
                                                    </Text>
                                                </MenuItem>
                                            )}
                                            {file.canWrite && (
                                                <MenuItem key="delete" onClick={toggleDelete}>
                                                    <TrashIcon />
                                                    <Text type="body2" className={classes.textMenu}>
                                                        Supprimer
                                                    </Text>
                                                </MenuItem>
                                            )}

                                        </MenuList>
                                    </ClickAwayListener>
                                </Paper>
                            </Grow>
                        )}
                    </Popper>

                </Box>
            </span>
        </>);
}