import React, {useEffect, useState} from "react";
import axios from "../providers/axios";
import CustomAutocomplete from "./basics/CustomAutocomplete";

export default (props) => {
    const {value, onChange, onlyUsers} = props;

    const [groupsAndUsersSearch, setGroupsAndUsersSearch] = useState('');
    const [groupsAndUsersOptions, setGroupsAndUsersOptions] = useState([]);

    useEffect(() => {
        const URL = onlyUsers ? `users?name=${groupsAndUsersSearch}` : `searchGroupsAndUsers?search=${groupsAndUsersSearch}`;

        let searchTimeout = setTimeout(() => {
            if (groupsAndUsersSearch) {
                axios.get(URL).then(({data}) => {
                    if (data.results) {
                        setGroupsAndUsersOptions(data.results);
                    } else {
                        setGroupsAndUsersOptions(data);
                    }

                }).catch(err => {

                });
            }
        }, 1000);


        return () => {clearTimeout(searchTimeout);};
    }, [groupsAndUsersSearch]);

    async function searchGroupsAndUsers(event) {
        setGroupsAndUsersSearch(event.target.value);
    }

    function getOptionLabel(groupOrUser) {
        return !groupOrUser['email'] ? groupOrUser['name'] : `${groupOrUser['firstname']} ${groupOrUser['lastname']}`;

    }

    return (
        <CustomAutocomplete AutoCompleteProps={{
            onInputChange: searchGroupsAndUsers,
            options: groupsAndUsersOptions,
            multiple: true,
            value,
            onChange//:(e, newValue) => {setGroupsAndUsersSelectedForWrite(newValue)}
        }} getOptionLabel={getOptionLabel} />
    );
}