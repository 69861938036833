import React, { useEffect, useState } from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Text from "../components/basics/Text";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import Button from "@material-ui/core/Button";
import Chip from '@material-ui/core/Chip';
import Pagination from '@material-ui/lab/Pagination';
import { FaFilter } from "react-icons/fa";

import MailIcon from "../components/icons/MailIcon";
import Box from "@material-ui/core/Box";
import useSWR from "swr";
import { fetcher } from "../providers/axios";
import NewMessage from "../components/NewMessage";
import FilterUsers from "../components/FilterUsers";
import { TableSortLabel } from "@material-ui/core";
import useGlobalStyle from "../hooks/styles/useGlobalStyle";
import store from "../providers/store";
import Input from "../components/basics/Input";
import CustomAutocomplete from "../components/basics/CustomAutocomplete";
import Select from "../components/basics/Select";
import { getContrastYIQ } from "../utils";

const useStyles = makeStyles(theme => ({
    displayXsNone: {
        [theme.breakpoints.down('xs')]: {
            display: 'none'
        }
    },
    paginator: {
        '&  button': {
            border: '1px solid #E8E8E8'
        },
    },
    chip: {
        marginRight: theme.spacing(1)
    },
}));

export default (props) => {
    const classes = {
        ...useStyles(),
        ...useGlobalStyle()
    };

    const [page, setPage] = useState(0);

    const [filterCompany, setFilterCompany] = useState('');
    const [filterName, setFilterName] = useState('');
    const [filterGroups, setFilterGroups] = useState(props.filterGroupsIds || []);
    const [filterRole, setFilterRole] = useState('');

    const [filterUrlParams, setFilterUrlParams] = useState(getFilterUrlParams());

    useEffect(() => {
        const timeout = setTimeout(() => {
            setFilterUrlParams(getFilterUrlParams());
        }, 500);

        return () => { clearTimeout(timeout); };
    }, [filterCompany, filterGroups, filterName, filterRole]);

    const [orderBy, setOrderBy] = useState('name');
    const [orderDir, setOrderDir] = useState('desc');

    const numberOfResult = 10;

    function getOrderByUrlParams(): string {
        if (orderBy) {
            return `&orderBy=${orderBy}&orderDir=${orderDir || 'desc'}`
        }
        return '';
    }

    function getFilterUrlParams(): string {
        let urlParams = '';
        if (filterName.length) {
            urlParams += `&name=${filterName}`;
        }
        if (filterCompany.length) {
            urlParams += `&company=${filterCompany}`;
        }
        if (filterGroups.length) {
            urlParams += `&groups=${filterGroups.map(group => group.id).join(',')}`;
        }
        if (filterRole) {
            urlParams += `&role=${filterRole}`;
        }
        return urlParams;
    }

    function toggleOrderDir(): void {
        setOrderDir(orderDir === 'desc' ? 'asc' : 'desc');
    }

    function selectColumnForOrder(columName: string) {
        if (columName === orderBy) {
            toggleOrderDir();
        } else {
            setOrderBy(columName);
        }
    }


    const { data: dataUsers } = useSWR(`users?page=${page}&numberOfResult=${numberOfResult}${filterUrlParams}${getOrderByUrlParams()}`, fetcher, {
        revalidateOnFocus: false,
    });
    const { data: dataGroups, mutate: mutateGroups } = useSWR(`groups`, fetcher, {
        revalidateOnFocus: false,
    });

    const groups = dataGroups?.results


    const users = dataUsers && dataUsers.results;
    const totalUsers = dataUsers && dataUsers.total;
    const numberOfPages = Math.ceil(totalUsers / numberOfResult);


    return (
        <React.Fragment>
            <Box mt={3} mb={2} className={classes.pageHead}>
                <Text type="h2">
                    Annuaire
                </Text>
            </Box>
            <div className={classes.card}>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                <TableSortLabel onClick={e => selectColumnForOrder('name')} active={orderBy === 'name'} direction={orderDir as 'asc' | 'desc'}>
                                    <Text type="subtitle2">
                                        Nom
                                    </Text>
                                </TableSortLabel>
                                <Input required fullWidth value={filterName}
                                    onChange={e => setFilterName(e.target.value)} />
                            </TableCell>
                            <TableCell>
                                <TableSortLabel onClick={e => selectColumnForOrder('company')} active={orderBy === 'company'} direction={orderDir as 'asc' | 'desc'}>
                                    <Text type="subtitle2">
                                        Entreprise
                                    </Text>
                                </TableSortLabel>
                                <Input required fullWidth value={filterCompany}
                                    onChange={e => setFilterCompany(e.target.value)} />
                            </TableCell>
                            <TableCell>
                                <TableSortLabel hideSortIcon>
                                    <Text type="subtitle2">
                                        Groupe(s)
                                    </Text>
                                </TableSortLabel>

                                <CustomAutocomplete AutoCompleteProps={{
                                    options: groups || [],
                                    multiple: true,
                                    value: filterGroups,
                                    onChange: (e, newValue) => setFilterGroups(newValue)
                                }} getOptionLabel={group => group['name']}
                                />



                            </TableCell>
                            <TableCell className={classes.displayXsNone}>
                                <TableSortLabel onClick={e => selectColumnForOrder('role')} active={orderBy === 'role'} direction={orderDir as 'asc' | 'desc'}>
                                    <Text type="subtitle2">
                                        Rôle
                                    </Text>
                                </TableSortLabel>
                                <Select value={filterRole} onChange={e => setFilterRole(e.target.value)}>
                                    <option value="">
                                        Tous
                                    </option>
                                    <option value="admin" style={{font: '400 15px "Lato"'}}>
                                        Administrateur
                                    </option>
                                    <option value="user">
                                        Membre
                                    </option>
                                </Select>
                            </TableCell>
                            <TableCell align="right">
                                {/* 
                                    <Button>
                                    <Box mr={.3}>
                                        <OrderIcon />
                                    </Box>

                                    <Text type="small1">
                                        Tri
                                    </Text>
                                    </Button>
                                */}
                                {/*
                                <FilterUsers
                                    filterGroups={filterGroups}
                                    filterName={filterName}
                                    filterCompany={filterCompany}
                                    onChangeName={setFilterName}
                                    onChangeCompany={setFilterCompany}
                                    onChangeGroups={setFilterGroups}
                                />
                                */}
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {(users || []).filter(u => u.id != store.auth.id).map((user) => (
                            <TableRow key={user.id}>
                                <TableCell component="th" scope="row">
                                    <Text type="body2">
                                        {user.firstname} {user.lastname}
                                    </Text>
                                </TableCell>
                                <TableCell>
                                    <Text type="body2">
                                        {user.company}
                                    </Text>
                                </TableCell>
                                <TableCell>
                                    {user.groups.map(grp => (
                                        <Chip key={grp.id} style={{ background: grp.color || '#e0e0e0',  }} label={<Text type="small1">
                                            <span style={{color: getContrastYIQ(grp.color || '#e0e0e0')}}>
                                                {grp.name}
                                            </span>
                                        </Text>}
                                            className={classes.chip} />
                                    ))}
                                </TableCell>
                                <TableCell className={classes.displayXsNone}>
                                    <Text type="body2">
                                        {user.role === 'admin' ? 'Administrateur' : 'Membre'}
                                    </Text>
                                </TableCell>
                                <TableCell align="right">
                                    <NewMessage users={[user]}>
                                        <Box component="span" style={{ cursor: 'pointer' }}>
                                            <MailIcon />
                                        </Box>
                                    </NewMessage>

                                </TableCell>
                            </TableRow>
                        ))}
                        <TableRow>
                            <TableCell colSpan={5} style={{ borderBottom: 'none' }} component="th" scope="row">
                                <Pagination onChange={(e, value) => setPage(value - 1)} className={classes.paginator}
                                    color="primary" count={numberOfPages} shape="rounded" />
                            </TableCell>

                        </TableRow>
                    </TableBody>
                </Table>

            </div>

        </React.Fragment>
    );
};