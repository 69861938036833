import React, { useState, useEffect } from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Text from "../../components/basics/Text";
import { Divider, Fab } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import ArrowBottomIcon from "../../components/icons/ArrowBottomIcon";
import Input from "../../components/basics/Input";
import Chip from "@material-ui/core/Chip";
import MenuItem from "@material-ui/core/MenuItem";
import MuiSelect from "@material-ui/core/Select";
import { useParams } from 'react-router-dom';
import File from "../../components/File";
import useSWR from "swr";
import axios, { fetcher } from "../../providers/axios";
import EditFolderModal from "../../components/EditFolderModal";
import EditFileModal from "../../components/EditFileModal";
import DeleteFileModal from "../../components/DeleteFileModal";
import { API_URL } from "../../config";
import MoveFilesModal from "../../components/MoveFilesModal";
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import useGlobalStyle from "../../hooks/styles/useGlobalStyle";
import store from "../../providers/store";

const useStyles = makeStyles(theme => ({
    cardContent: {
        padding: '12px',
    },
    filesContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'baseline',
        [theme.breakpoints.down('xs')]: {
            justifyContent: 'space-between',
            '&>*': {
                maxWidth: '49%',
            },
        }
    },
    icon: {
        width: '100px',
        height: '100px'
    },
    boxFile: {
        display: "flex",
        alignItems: "flex-start",
        padding: `5px 10px`,
        cursor: 'pointer',
        borderRadius: '5px',
        '&:hover': {
            backgroundColor: '#EBF0FF',
            '& .MuiCheckbox-root': {
                opacity: 1
            }
        }

    },
    checkboxFile: {
        opacity: 0,
        '&:hover': {
            opacity: 1
        }
    },
    fab: {
        position: 'fixed',
        zIndex: 1000,
        bottom: 10,
        right: 10,
    }
}));


export default (props) => {
    const classes = {
        ...useStyles(),
        ...useGlobalStyle()
    };
    const [filesSelected, setFilesSelected] = useState([]);

    const [openAddFolder, setOpenAddFolder] = useState(false);
    const [openAddFile, setOpenAddFile] = useState(false);
    const [openDeleteSelection, setOpenDeleteSelection] = useState(false);
    const [openMoveSelection, setOpeMoveSelection] = useState(false);

    const [idActionChoose, setIdActionChoose] = useState('');

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

    const { id } = useParams();

    let groupedActions = [
        {
            id: '1',
            name: 'Supprimer',
            func: () => {
                toggleDeleteSelection();
            }
        }
    ];

    const isInRoot = !id;
    if (!isInRoot) {
        groupedActions.push({
            id: '2',
            name: 'Télécharger',
            func: () => {
                downloadSelection();
            }
        });
        groupedActions.push({
            id: '3',
            name: 'Déplacer',
            func: () => {
                toggleMoveSelection();
            }
        });
    }

    useEffect(() => {
        setFilesSelected([]);
    }, [id]);


    const { data: dataFiles, mutate: mutateFiles } = useSWR(`files/${id || ''}`, fetcher, {
        revalidateOnFocus: false,
    });


    function toggleOpenAddFolder() {
        setOpenAddFolder(!openAddFolder);
    }

    function toggleOpenAddFile() {
        setOpenAddFile(!openAddFile);
    }

    function toggleDeleteSelection() {
        setOpenDeleteSelection(!openDeleteSelection);
    }

    function toggleMoveSelection() {
        setOpeMoveSelection(!openMoveSelection);
    }

    function selectOrUnselect(file, event) {
        event.stopPropagation();

        if (filesSelected.some(f => f.id == file.id)) {
            setFilesSelected(filesSelected.filter(f => f.id != file.id));
        } else {
            setFilesSelected([...filesSelected, file]);
        }
    }

    async function downloadSelection() {
        try {
            for (const file of filesSelected) {
                const path = API_URL + file.path;


                const response = await axios.get(path, { responseType: 'arraybuffer' });
                let element = document.createElement('a');
                element.setAttribute('href', 'data:text/plain;base64,' + encodeURIComponent(Buffer.from(response.data, 'binary').toString('base64')));
                element.setAttribute('download', file.name);

                element.style.display = 'none';
                document.body.appendChild(element);

                element.click();

                document.body.removeChild(element);
            }
            setFilesSelected([]);
        }
        catch (err) {

        }
    }

    return (
        <React.Fragment>
            <EditFolderModal mutate={mutateFiles} open={openAddFolder} toggleOpen={toggleOpenAddFolder} />
            <EditFileModal folderId={id} mutate={mutateFiles} open={openAddFile} toggleOpen={toggleOpenAddFile} />

            <Box mt={3} mb={2} className={classes.pageHead}>
                <Text type="h2">
                    Fichiers
                </Text>
            </Box>
            <div className={classes.card}>
                <div className={classes.cardHead}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>

                        {isMobile ? (
                            <>
                                <Fab color="primary" className={classes.fab} onClick={!id ? toggleOpenAddFolder : toggleOpenAddFile}>
                                    <Text type="h3" color="white">
                                        <span style={{ color: 'white' }}>
                                            +
                                        </span>
                                    </Text>
                                </Fab>
                            </>
                        ) : (!id ? (
                            (store.isAuth && store.auth.role === 'admin') ?
                                (
                                    <Box mr={2}>
                                        <Button onClick={toggleOpenAddFolder} variant="contained" color="primary">
                                            <Box mr={1}>
                                                +
                                            </Box>
                                            NOUVEAU DOSSIER
                                        </Button>

                                    </Box>
                                ) : <></>
                        ) : (
                            <Box mr={2}>
                                <Button onClick={toggleOpenAddFile} variant="contained" color="primary">
                                    <Box mr={1}>
                                        <ArrowBottomIcon />
                                    </Box>
                                    AJOUTER UN FICHIER
                                </Button>
                            </Box>
                        ))}


                        <Box display="flex" alignItems="center">
                            <MuiSelect
                                displayEmpty
                                input={<Input />}
                                renderValue={(selected) => selected ? ((groupedActions || []).find(ga => ga.id == selected) || { name: '' }).name : 'Actions groupées'}
                                value={idActionChoose}
                                onChange={(e) => setIdActionChoose(e.target.value as string)}
                            >
                                <MenuItem>
                                    Actions groupées
                                </MenuItem>
                                {groupedActions.map((action) => (
                                    <MenuItem key={action.id} value={action.id}>
                                        {action.name}
                                    </MenuItem>
                                ))}
                            </MuiSelect>


                            <Box ml={1} component="span">
                                <Button variant="contained" color="primary"
                                    disabled={!idActionChoose || !filesSelected.length} onClick={e => {
                                        groupedActions.find(g => g.id == idActionChoose).func();
                                    }} >
                                    <Text type="small1" color="white">
                                        Appliquer
                                    </Text>

                                </Button>
                                {!!filesSelected && (
                                    <>
                                        <DeleteFileModal setFilesSelected={setFilesSelected} toggleOpen={toggleDeleteSelection} open={openDeleteSelection} mutate={mutateFiles}
                                            id={filesSelected.map(f => f.id)} />
                                        <MoveFilesModal setFilesSelected={setFilesSelected} toggleOpen={toggleMoveSelection} open={openMoveSelection} mutate={mutateFiles}
                                            files={filesSelected} />
                                    </>
                                )}
                            </Box>

                        </Box>
                    </div>

                </div>
                <Divider />
                <Box className={classes.cardContent}>
                    <Box className={classes.filesContainer}>
                        {(dataFiles || []).map(file => (
                            <File key={file.id} mutate={mutateFiles} file={file} filesSelected={filesSelected}
                                selectOrUnselect={selectOrUnselect} />
                        ))}
                    </Box>
                </Box>
            </div>
        </React.Fragment>
    );
}