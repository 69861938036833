import React, {useState} from "react";
import Text from "../basics/Text";
import {Popper} from "@material-ui/core";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import MenuList from "@material-ui/core/MenuList";
import MenuItem from "@material-ui/core/MenuItem";
import {Link} from 'react-router-dom';
import ArrowBottomMenuIcon from "../icons/ArrowBottomMenuIcon";
import store from "../../providers/store";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import ErrorIcon from "../icons/ErrorIcon";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Box from "@material-ui/core/Box";
import DialogContentText from "@material-ui/core/DialogContentText";
import Button from "@material-ui/core/Button";
import ModalConfirmDisconnect from "../ModalConfirmDisconnect";
import { useHistory } from 'react-router-dom';



export default ({classes}) => {
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef<HTMLButtonElement>(null);
    const [modalConfirmDisconnectOpen, setModalConfirmDisconnectOpen] = useState(false);

    const history = useHistory();

    function toggleModalConfirmDisconnect() {
        setModalConfirmDisconnectOpen(!modalConfirmDisconnectOpen);
    }


    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event: React.MouseEvent<EventTarget>) => {
        if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
            return;
        } 
    

        setOpen(false);
    };

    function handleListKeyDown(event: React.KeyboardEvent) {
        if (event.key === 'Tab') {
            event.preventDefault();
            setOpen(false);
        }
    }

    // return focus to the button when we transitioned from !open -> open
    const prevOpen = React.useRef(open);
    React.useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current!.focus();
        }

        prevOpen.current = open;
    }, [open]);

    function handleDisconnect(event) {
        handleClose(event);
        store.disconnect();
    }

    function goTo(path, event) {
        handleClose(event);
        store.goToLink(path);
    }

    return (
        <React.Fragment>
            <Box  mr={2} ml={2}>
                <span ref={anchorRef} style={{cursor: 'pointer'}}
                      aria-controls={open ? 'menu-list-grow' : undefined}
                      aria-haspopup="true"
                      onClick={handleToggle}
                >
                <Text type="subtitle2" className={`${classes.link} ${store.currentRoute.includes('/profile') ? 'active' : ''}`} component="span">
                    Mon compte
                    <i style={{marginLeft: '5px'}}>
                        <ArrowBottomMenuIcon/>
                    </i>
                </Text>
            </span>
            </Box>
            <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal style={{zIndex: 1000}}>
                {({TransitionProps, placement}) => (
                    <Grow
                        {...TransitionProps}
                        style={{transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom'}}
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                                    <MenuItem onClick={e => goTo('/profile', e)}>Profil</MenuItem>
                                    <MenuItem onClick={toggleModalConfirmDisconnect}>Deconnexion</MenuItem>
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>

            <ModalConfirmDisconnect onConfirm={handleDisconnect} open={modalConfirmDisconnectOpen}
                                    toggle={toggleModalConfirmDisconnect}/>
        </React.Fragment>
    );
};