import { Avatar, Box, Button, FormControl, makeStyles } from '@material-ui/core';
import React, { useState } from 'react';
import Input from '../components/basics/Input';
import Text from '../components/basics/Text';
import axios from '../providers/axios';
import { useSnackbar } from "notistack";

const useStyles = makeStyles(theme => ({
    subtitle: {
        color: '#1F2836!important'
    },
    checkIcon: {
        backgroundColor: theme.palette.success.main
    },
    returnBtn: {
        color: '#BDBDBD'
    }
}));

export default ({ setDisplayForgetPassword }) => {
    const classes = useStyles();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();


    async function send(event) {

        event.preventDefault();
        const formData = new FormData(event.target);
        let data = {
            email: '',
        };
        for (const property in data) {
            data[property] = formData.get(property);
        }

        console.log(data);
        try {
            await axios.post('forget-password', data);
            enqueueSnackbar(`Demande de réinitialisation de mot de passe envoyé !`, { variant: 'success' });
            setDisplayForgetPassword(false);
        } catch (err) {
            enqueueSnackbar(`Une erreur est survenue`, { variant: 'error' });
        }
    }

    return (
        <>

            <Box display="flex" justifyContent="space-between" alignItems="center">
                <Text type="h3">
                    Mot de passe oublié
                </Text>
                <Box component="span" style={{ cursor: 'pointer' }} onClick={e => setDisplayForgetPassword(false)}>
                    <Text type="small1" className={classes.returnBtn}>
                        {"<"} Retour
                    </Text>
                </Box>
            </Box>
            <form onSubmit={send}>
                <Box mb={2} mt={1}>

                    <FormControl fullWidth>
                        <Box mt={2} mb={.3}>
                            <label htmlFor="email">
                                <Text type="subtitle2">
                                    Adresse email
                                </Text>
                            </label>
                        </Box>
                        <Input type="email" required fullWidth id="email" name="email" />
                    </FormControl>
                    
                  



                </Box>


                <Button type="submit" variant="contained" color="primary" fullWidth>
                    <Text type="small1" color="white">
                        Réinitialiser le mot de passe
                        </Text>
                </Button>

            </form>


        </>
    );
};