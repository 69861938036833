import React from "react";
import {Typography} from "@material-ui/core";
import {Variant} from "@material-ui/core/styles/createTypography";
import makeStyles from "@material-ui/core/styles/makeStyles";
import typography from "../../theme/typography";

interface ITextProps {
    type: Variant | 'small1' | 'small2' | 'small3',
    children: any,
    component?: string,
    className?: string,
    color?: string
}


const useStyles = makeStyles(typography);

export default (props: ITextProps) => {
    const classes = useStyles();
    const variant = ['small1', 'small2', 'small3'].includes(props.type) ? 'body2' : props.type;
    let style = {}
    if (!props.color) {
        style = {color: "#52575C"}
    }


    return (
        <React.Fragment>
            {/* @ts-ignore */}
            <Typography color={(props.color && props.color !== 'white') ? props.color : 'initial'} style={style} component={props.component} className={classes[props.type] + ' ' + props.className || ''} variant={variant}>
                {props.children}
            </Typography>
        </React.Fragment>
    );
};