/* eslint-disable no-use-before-define */
import React from 'react';
import useAutocomplete from '@material-ui/lab/useAutocomplete';
import NoSsr from '@material-ui/core/NoSsr';
import styled from 'styled-components';
import makeStyles from "@material-ui/core/styles/makeStyles";
import {fade} from "@material-ui/core";
import Chip from "@material-ui/core/Chip";
import Input from './Input';
import { getContrastYIQ } from '../../utils';

const useStyle = makeStyles(theme => ({
    colorChip: {
      '& .MuiSvgIcon-root': {
        color: 'inherit',
      }
    },
    chip: {
        marginRight: theme.spacing(1),
        border: '1px solid rgba(29, 112, 183, 0.37)',
    
    },
    input: {

            borderRadius: 4,
            position: 'relative',
            fontSize: 14,
            backgroundColor: theme.palette.common.white,
            border: '1px solid #ced4da',
            width: '100%',
           
            
            
            transition: theme.transitions.create(['border-color', 'box-shadow']),
            '&:focus': {
                boxShadow: `${fade(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
                borderColor: theme.palette.primary.light,
            },

    }
}));

const Label = styled('label')`
  padding: 0 0 4px;
  line-height: 1.5;
  display: block;
`;

const InputWrapper = styled('div')`
  width: 100%;
  border: 1px solid #d9d9d9;
  background-color: #fff;
  border-radius: 4px;
  display: flex;
  flex-wrap: wrap;
  /*height: 36px;*/
  
  padding: 4px;

  

  &.focused {
    border-color: #A9C1FD;
    box-shadow: rgb(29 112 183 / 25%) 0 0 0 0.1rem;
  }

  & input {
    font-size: 14px;
    height: 30px;
    box-sizing: border-box;
    width: 0;
    min-width: 30px;
    flex-grow: 1;
    border: 0;
    margin: 0;
    outline: 0;
  }
`;



const Tag = (props) => (
    <Chip {...props} />
)
/*

const Tag = styled(({ label, onDelete, ...props }) => (
    <div {...props}>
        <span>{label}</span>
        <CloseIcon onClick={onDelete} />
    </div>
))`
  display: flex;
  align-items: center;
  height: 24px;
  margin: 2px;
  line-height: 22px;
  background-color: #fafafa;
  border: 1px solid #e8e8e8;
  border-radius: 2px;
  box-sizing: content-box;
  padding: 0 4px 0 10px;
  outline: 0;
  overflow: hidden;

  &:focus {
    border-color: #40a9ff;
    background-color: #e6f7ff;
  }

  & span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  & svg {
    font-size: 12px;
    cursor: pointer;
    padding: 4px;
  }
`;
*/

const Listbox = styled('ul')`
  width: 100%;
  margin: 2px 0 0;
  padding: 0;
  position: absolute;
  list-style: none;
  background-color: #fff;
  overflow: auto;
  max-height: 250px;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  z-index: 1;

  & li {
    padding: 5px 12px;
    display: flex;

    & span {
      flex-grow: 1;
    }

    & svg {
      color: transparent;
    }
  }

  & li[aria-selected='true'] {
    background-color: #fafafa;
    font-weight: 600;

    & svg {
      color: #1890ff;
    }
  }

  & li[data-focus='true'] {
    background-color: #e6f7ff;
    cursor: pointer;

    & svg {
      color: #000;
    }
  }
`;

export default (props) => {
  const {AutoCompleteProps, getOptionLabel, style} = props;
    const {
        getRootProps,
        getInputLabelProps,
        getInputProps,
        getTagProps,
        getListboxProps,
        getOptionProps,
        groupedOptions,
        value,
        focused,
        setAnchorEl,
    } = useAutocomplete(
        {...AutoCompleteProps, getOptionLabel}
    );


    const classes = useStyle();

    return (
        <NoSsr>
            <div style={style || {}}>
                <div {...getRootProps()}>
                    <InputWrapper  ref={setAnchorEl}  className={(focused ? 'focused' : '') + ' ' +classes.input}>
                        {Array.isArray(value) && value.map((option, index: number) => (
                            <Tag
                              key={option.id}
                              variant={!option.color ? "outlined" : "default"}
                              className={!option.color ? classes.chip : classes.colorChip} 
                              style={{
                                  background: option.color || 'white',
                                  color: option.color ? getContrastYIQ(option.color) : 'rgba(0, 0, 0, 0.65)',
                                  marginRight: '3px',
                                  
                              }} 
                              label={getOptionLabel(option)} {...getTagProps({ index })}
                              
                            />
                        ))}
                        <input {...getInputProps()} />
                    </InputWrapper>
                </div>
                {groupedOptions.length > 0 ? (
                    <Listbox {...getListboxProps()}>
                        {groupedOptions.map((option, index) => (
                            <li key={option['id']} {...getOptionProps({ option, index })}>
                                <span>{getOptionLabel(option)}</span>
                            </li>
                        ))}
                    </Listbox>
                ) : null}
            </div>
        </NoSsr>
    );
}
