
import MoveIcon from './icons/MoveIcon';
import { MenuItem, MenuList } from '@material-ui/core';
import React, { useEffect, useState } from "react";
import Text from "./basics/Text";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import { Popper } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import Input from "./basics/Input";
import makeStyles from "@material-ui/core/styles/makeStyles";
import useSWR from "swr";
import { fetcher } from "../providers/axios";
import CustomAutocomplete from "./basics/CustomAutocomplete";
import FolderIcon from './icons/FolderIcon';

const useStyles = makeStyles(theme => ({
    paper: {
        padding: theme.spacing(1.5),
        textAlign: 'left'
    },
    textMenu: {
        marginLeft: theme.spacing(1)
    }
}));


export default (props) => {
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef<HTMLButtonElement>(null);
    const classes = useStyles();


    const { data: inCacheFolders } = useSWR('files?canWriteFilter=1', fetcher, {
        revalidateOnFocus: false,
        revalidateOnMount: false,
        revalidateOnReconnect: false,
    });

    const { data: folders } = useSWR('files?canWriteFilter=1', fetcher, {
        revalidateOnFocus: false,
        revalidateOnMount: !inCacheFolders,
        revalidateOnReconnect: false,
        initialData: inCacheFolders
    });

    function handleMove(folder) {
        typeof props.moveFile === 'function' && props.moveFile(folder);
    }



    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event: React.MouseEvent<EventTarget>) => {
        if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
            return;
        }

        setOpen(false);
    };



    return (
        <span key="move">
            <span >
                <MenuItem onClick={handleToggle} innerRef={anchorRef}>

                    <MoveIcon />
                    <Text type="body2" className={classes.textMenu}>
                        Déplacer
                    </Text>

                </MenuItem>
            </span>

            <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal
                style={{ zIndex: 1000 }} placement="right-start">
                {({ TransitionProps, placement }) => (
                    <Grow {...TransitionProps} style={{transformOrigin: 'left bottom'}}>
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList autoFocusItem={open}>
                                    {folders.map(folder => (
                                        <MenuItem key={folder.id} onClick={e => handleMove(folder)}>

                                            <Text type="body2">
                                                {folder.name}
                                            </Text>

                                        </MenuItem>
                                    ))}

                                </MenuList>
                            </ClickAwayListener>
                        </Paper>

                    </Grow>
                )}
            </Popper>
        </span>
    )

};