export default {
    primary: {
        main: '#1D70B7',
        light: '#A9C1FD',
        dark: '#155892'
    },
    error: {
        main: '#FF6760',
        light: '#FF7E77',
        dark: '#E74F48'
    },
    success:  {
        main: '#759719',
        light: 'rgba(117, 151, 25, 0.3)',
        dark: '#738f28'
    },
    warning: {
        main: '#FAC032',
        light: '#FCDF98',
        dark: '#EFAD0A'
    }
}