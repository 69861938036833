import React, { useEffect } from "react";
import Text from "../basics/Text";
import { Button, Divider, makeStyles, Popper } from "@material-ui/core";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import { useHistory } from 'react-router-dom';
import Box from "@material-ui/core/Box";
import { Badge } from "@material-ui/core";
import BellIcon from "../icons/BellIcon";
import axios from '../../providers/axios';
import store from "../../providers/store";
import { FaEnvelope, FaFile, FaUserPlus } from "react-icons/fa";
const { htmlToText } = require('html-to-text');

export enum EnumNotificationType {
    newMessage = 'newMessage',
    newFileAccess = 'newFileAccess',
    newUserToValidateByAdmin = 'newUserToValidateByAdmin',
}

export default ({ classes, notifications, mutate: mutateNofications }) => {
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef<HTMLButtonElement>(null);
    const history = useHistory();



    const handleToggle = () => {
        setOpen((prevOpen) => {
            if (prevOpen && Array.isArray(notifications) && notifications.length) {
                const notificationsIds = notifications.map(({ id }) => id);
                axios.put('notifications', { notificationsIds });
                mutateNofications(notifications => {
                    return notifications.map(n => ({...n, read: true}));
                })
            }
            return !prevOpen
        });
        
    };

    const handleClose = (event: React.MouseEvent<EventTarget>) => {
        if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
            return;
        }
        if (Array.isArray(notifications) && notifications.length) {
            const notificationsIds = notifications.map(({ id }) => id);
            axios.put('notifications', { notificationsIds });
            mutateNofications(notifications => {
                return notifications.map(n => ({...n, read: true}));
            })
        }

        setOpen(false);
    };



    async function setNotificationAdminRead(notification, event) {
        axios.put('notifications', { notificationsIds: [notification.id] });
        goTo('/admin/users', event, notification);
    }

    async function goTo(path, event, notification) {
        handleClose(event);
        mutateNofications(notifications => {
            return notifications.map(notif => notif.id == notification.id ? { ...notif, read: true } : notif);
        }, false);
        if (store.currentRoute !== path) {
            history.push('/');
            store.goToLink(path);
        }
        
        //history.push(path);
    }

    const numerOfNotificationNotRead = (notifications || []).filter(f => !f.read).length;

    return (
        <>

            <Button
                ref={anchorRef}
                aria-controls={open ? 'menu-list-grow' : undefined}
                aria-haspopup="true"
                onClick={handleToggle}
                style={{ padding: 0, background: open ? 'white' : 'initial' }}
            >

                {numerOfNotificationNotRead ? (
                    <Badge badgeContent={numerOfNotificationNotRead} color="error" className={classes.badge}>
                    <BellIcon />
                </Badge>
                ) : <BellIcon />}


            </Button>

            <Popper placement="bottom-start" open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal style={{ zIndex: 1000 }}>
                {({ TransitionProps, placement }) => (
                    <Grow {...TransitionProps}>
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <Box>
                                    {(!Array.isArray(notifications) || (Array.isArray(notifications) && !notifications.length)) && (
                                        <Box p={2}>
                                            <Text type="body1">
                                                Aucune notification.
                                            </Text>
                                        </Box>
                                    )}
                                    {(notifications || []).map((notification, index) => (
                                        <Box key={notification.id} className={classes.menuLink + ` ${!notification.read ? classes.notificationNotRead : classes.notificationRead}`} >

                                            {notification.type === EnumNotificationType.newMessage && (
                                                <Box p={2} onClick={e => goTo(`/conversations/${notification.conversationId}/messages/${notification.modelId}`, e, notification)} display="flex" alignItems="center">
                                                    {notification.read ? <FaEnvelope fontSize={20} /> : <FaEnvelope fontSize={20}  />}
                                                    <Box ml={1}>
                                                        <Text type="small3">

                                                            {notification.senderName}

                                                            <span style={{ fontWeight: 'normal' }}> vous a envoyé un message</span>
                                                        </Text>
                                                        <Text type="small3">
                                                            <span style={{ color: '#979797' }}>
                                                                {notification.subject}
                                                            </span>

                                                        </Text>
                                                    </Box>
                                                </Box>

                                            )}

                                            {notification.type === EnumNotificationType.newFileAccess && (
                                                <Box p={2} onClick={e => goTo(`/files/${notification.modelId}`, e, notification)} display="flex" alignItems="center">
                                                    {notification.read ? <FaFile fontSize={20} /> : <FaFile fontSize={20} />}
                                                    <Box ml={1}>
                                                        <Text type="small3">

                                                            {notification.fileUpdaterName || 'Un utilisateur'}

                                                            <span style={{ fontWeight: 'normal' }}> a partagé un fichier avec vous</span>
                                                        </Text>
                                                        <Text type="small3">
                                                            <span style={{ color: '#979797' }}>
                                                                Mise à jour des accès au dossier <strong>{notification.fileName}</strong> !
                                                        </span>

                                                        </Text>
                                                    </Box>
                                                </Box>
                                            )}


                                            {notification.type === EnumNotificationType.newUserToValidateByAdmin && (
                                                <Box p={2} onClick={e => setNotificationAdminRead(notification, e)} display="flex" alignItems="center">
                                                    {notification.read ? <FaUserPlus fontSize={20} /> : <FaUserPlus fontSize={20} />}
                                                    <Box ml={1}>
                                                        <Text type="small3">
                                                            Nouvelle demande d'inscription 
                                                            
                                                            
                                                        </Text>
                                                        <Text type="small3">
                                                        {notification.newUserName && (
                                                                <>
                                                                    <span style={{ fontWeight: 'normal' }}> {notification.newUserName} </span>
                                                                </>
                                                            )}
                                                        </Text>
                                                    </Box>
                                                </Box>
                                            )}


                                            {index != notifications.length - 1 && <Box ml={1} mr={1}><Divider /></Box>}

                                        </Box>
                                    ))}
                                </Box>

                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </>
    );
};