import React, {useEffect, useRef, useState} from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Dialog from "@material-ui/core/Dialog";
import Box from "@material-ui/core/Box";
import ErrorIcon from "./icons/ErrorIcon";
import Text from "./basics/Text";
import Button from "@material-ui/core/Button";
import {Grid} from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import Input from "./basics/Input";
import Select from "./basics/Select";
import axios from '../providers/axios';
import store from "../providers/store";
import CustomAutocomplete from "./basics/CustomAutocomplete";
import GroupsAndUsersSearchInput from "./GroupsAndUsersSearchInput";
import {useSnackbar} from "notistack";

interface IEditFolderProps {
    folder?: any,
    mutate: Function,
    toggleOpen: any,
    open: boolean
}

const useStyle = makeStyles(theme => ({
    row: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'baseline'
    },
    rowButtons: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center'
    },
    dialog: {
        padding: '15px',
        '& .MuiPaper-root': {
            minWidth: '50%'

        }
    },
    content: {
        padding: theme.spacing(2),
    },
    closeBox: {
        color: '#9FA2B4',
        fontWeight: 400,
        fontSize: '24px',
        letterSpacing: '0.2px'
    }
}));

export default (props: IEditFolderProps) => {
    const classes = useStyle();
    const formRef = useRef(null);
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const {open, toggleOpen, mutate, folder} = props;

    const [groupsAndUsersSelectedForSee, setGroupsAndUsersSelectedForSee] = useState([]);
    const [groupsAndUsersSelectedForDownload, setGroupsAndUsersSelectedForDownload] = useState([]);
    const [groupsAndUsersSelectedForWrite, setGroupsAndUsersSelectedForWrite] = useState([]);

    function emptyForm(form) {
        setGroupsAndUsersSelectedForSee([]);
        setGroupsAndUsersSelectedForDownload([]);
        setGroupsAndUsersSelectedForWrite([]);
        for (let elem in form.elements) {
            elem = '';
        }
    }

    async function edit(event) {
        event.preventDefault();
        const isAddAction = !(folder && folder.id);

        const formData = new FormData(event.target);

        let data = {};
        for (const property of ['name']) {
            data[property] = formData.get(property);
        }
        

        const isGroup = groupOrUser => !groupOrUser.email;
        const isUser = groupOrUser => !!groupOrUser.email;
        data = {
            ...data,
            canSeeUserIds:groupsAndUsersSelectedForSee.filter(isUser).map(({id}) => id),
            canSeeGroupIds:groupsAndUsersSelectedForSee.filter(isGroup).map(({id}) => id),
            canDownloadUserIds:groupsAndUsersSelectedForDownload.filter(isUser).map(({id}) => id),
            canDownloadGroupIds:groupsAndUsersSelectedForDownload.filter(isGroup).map(({id}) => id),
            canWriteUserIds:groupsAndUsersSelectedForWrite.filter(isUser).map(({id}) => id),
            canWriteGroupIds: groupsAndUsersSelectedForWrite.filter(isGroup).map(({id}) => id),
        }

        
        try {
            if (isAddAction) {
                const folder = (await axios.post('files', data)).data;

            } else {
                const folder = (await axios.put(`files/${props.folder.id}`, data)).data;
            }
            enqueueSnackbar('Dossier enregistré avec succès !', {variant: 'success'});
            mutate();
            toggleOpen();
            emptyForm(event.target);
    

        } catch (err) {
            enqueueSnackbar('Erreur lors de l\'enregistrement du dossier', {variant: 'error'});
            toggleOpen();
        }
    }


    useEffect(() => {
        if (folder && folder.accesses) {
            let canWrite = folder.accesses.filter(access => access.canWrite);
            let canDownload = folder.accesses.filter(access => access.canDownload);
            let canSee = folder.accesses.filter(access => access.canSee);

            canSee = canSee.filter((access, index) => !canSee.some((a, i) => i < index && a.userId == access.userId && a.groupId == access.groupId));
            canWrite = canWrite.filter((access, index) => !canWrite.some((a, i) => i < index && a.userId == access.userId && a.groupId == access.groupId));
            canDownload = canDownload.filter((access, index) => !canDownload.some((a, i) => i < index && a.userId == access.userId && a.groupId == access.groupId));

            canSee = canSee.map(c => c.user ? c.user : c.group);
            canDownload = canDownload.map(c => c.user ? c.user : c.group);
            canWrite = canWrite.map(c => c.user ? c.user : c.group);

            setGroupsAndUsersSelectedForWrite(canWrite);
            setGroupsAndUsersSelectedForDownload(canDownload);
            setGroupsAndUsersSelectedForSee(canSee);
        }

        let interval = setInterval(() => {
            if ((formRef && formRef.current) || !props.folder) {
                if (props.folder) {
                    for (const property in props.folder) {
                        if (formRef.current[property]) {
                            formRef.current[property].value = props.folder[property];
                        }
                    }
                }
                clearInterval(interval);
            }
        }, 50);

        return () => {
            clearInterval(interval)
        };
    }, [open]);

    return (
        <>
            <Dialog onClose={toggleOpen} className={classes.dialog} open={open}>
                <div className={classes.content} onClick={e => e.stopPropagation()}>
                    <Box mb={.1} className={classes.rowButtons}>
                        <Box component="span" style={{cursor: 'pointer'}} onClick={toggleOpen}>
                            <span className={classes.closeBox}>X</span>
                        </Box>
                    </Box>
                    {props.folder && props.folder.id ? (
                        <Text type="h3">
                            Modifier "{props.folder.name}"
                        </Text>
                    ) : (
                        <Text type="h3">
                            Nouveau dossier
                        </Text>
                    )}
                    <form onSubmit={edit} ref={formRef}>
                        <Box mb={1} mt={1}>
                            <Grid container>
                                <Grid item xs={12} sm={12}>
                                    <Box mr={2}>
                                        <FormControl fullWidth>
                                            <Box mt={2} mb={.3}>
                                                <label htmlFor="name">
                                                    <Text type="subtitle2">
                                                        Nom
                                                    </Text>
                                                </label>
                                            </Box>
                                            <Input required fullWidth id="name" name="name"/>
                                        </FormControl>
                                        
                                            <>
                                                <Box mt={2} mb={.3}>

                                                    <Text type="subtitle2">
                                                        Gestion des accès
                                                    </Text>

                                                </Box>
                                                <FormControl fullWidth>
                                                    <Box mt={2} mb={.3} ml={1}>
                                                        <label>
                                                            <Text type="body2">
                                                                Peut modifier
                                                            </Text>
                                                        </label>
                                                        <GroupsAndUsersSearchInput
                                                            value={groupsAndUsersSelectedForWrite}
                                                            onChange={(e, newValue) => {
                                                                setGroupsAndUsersSelectedForWrite(newValue)
                                                            }}/>
                                                    </Box>
                                                </FormControl>
                                                <FormControl fullWidth>
                                                    <Box mt={2} mb={.3} ml={1}>
                                                        <label htmlFor="can_download">
                                                            <Text type="body2">
                                                                Peut télécharger
                                                            </Text>
                                                        </label>
                                                        <GroupsAndUsersSearchInput
                                                            value={groupsAndUsersSelectedForDownload}
                                                            onChange={(e, newValue) => {
                                                                setGroupsAndUsersSelectedForDownload(newValue)
                                                            }}/>
                                                    </Box>
                                                </FormControl>
                                                <FormControl fullWidth>
                                                    <Box mt={2} mb={.3} ml={1}>
                                                        <label htmlFor="can_see">
                                                            <Text type="body2">
                                                                Consultation seule
                                                            </Text>
                                                        </label>
                                                        <GroupsAndUsersSearchInput
                                                            value={groupsAndUsersSelectedForSee}
                                                            onChange={(e, newValue) => {
                                                                setGroupsAndUsersSelectedForSee(newValue)
                                                            }}/>
                                                    </Box>
                                                </FormControl>
                                            </>
                                        
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                        <Box mb={1} mt={2} className={classes.row}>
                            <Box mr={1}>
                                <Button variant="contained" color="primary" type="submit">
                                    <Text type="small1" color="white">
                                        ENREGISTRER
                                    </Text>
                                </Button>
                            </Box>
                            <Box>
                                <Button onClick={toggleOpen} type="button">
                                    <Text type="small1">
                                    ANNULER
                                    </Text>
                                </Button>
                            </Box>

                        </Box>
                    </form>
                </div>
            </Dialog>
        </>
    );
};