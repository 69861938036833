import axios from '../../providers/axios';
import MessageList from "./MessageList";


export default (props) => {
    return <MessageList
        title="Messages envoyés"
        displayStatusResponded={false}
        getUrl={({ page, numberOfResult, dateOrder }) => `message/send?page=${page}&numberOfResult=${numberOfResult}&order=${dateOrder}`}
        getGroupedActions={({ enqueueSnackbar, selectedMessages, setAllSelectedChecked, setSelectedMessages, mutate, setMessageTOdisplay }) => {
            return [
                {
                    id: '1', name: 'Supprimer', func: async () => {
                        try {
                            await axios.post('/messages/delete', {
                                ids: selectedMessages.map(({ id }) => id)
                            });
                            enqueueSnackbar('Message(s) supprimé avec succès !', { variant: 'success' });
                            setAllSelectedChecked(false);
                            setSelectedMessages([]);
                            mutate();
                            setMessageTOdisplay(null);
                        } catch (err) {
                            enqueueSnackbar('Erreur lors de la suppréssion du message', { variant: 'error' });
                            console.error(err)
                        }

                    }
                },
            ]
        }} />
}
